import axios from "axios"
import { API_URL } from "@env"

declare module "axios" {
  interface AxiosRequestConfig {
    cache?: boolean
    fetchFromCacheIfOffline?: boolean
    syncOnceOnline?: boolean
  }
}

const Api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  withCredentials: true,
  // adapter: CacheSyncAdapter,
})

Api.interceptors.response.use(
  async (response) => {
    if (response.data && (response.data.success === false || response.data.error)) {
      const message = response.data.error
      // Toast.show({
      //   text: message,
      //   type: "warning",
      //   buttonText: "Dismiss",
      //   duration: 5000
      // });
      throw new Error(message)
    }
    return response
  },
  async (error) => {
    // if (error.response?.status === 401) {
    //   const isLoggedIn = select(selectIsLoggedIn);
    //   if (isLoggedIn) {
    //     Alert.alert(
    //       "Unauthenticated",
    //       "Your account has logged out. Please login again"
    //     );
    //     redux.store.dispatch({ type: "LOGOUT" });
    //   }
    // }
    console.log(error.response)
    var message = error.response ? error.response.data.responseException.exceptionMessage : "Network error"
    // Toast.show({
    //   text: message,
    //   type: "warning",
    //   buttonText: "Dismiss",
    //   duration: 5000,
    // })
    return Promise.reject(error)
  }
)

export default Api

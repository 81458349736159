import React from "react"
import { Container, Content, View, Button, Icon, Textarea, CheckBox, Col } from "native-base"
import { StyleSheet, Text, Image, Dimensions, LayoutAnimation } from "react-native"
import { Layout } from "@theme/layout"
import Loading from "@components/Loading"
import { Color } from "@theme/color"
import SCREEN from "@utils/screen"
import { ProductModel } from "@define/models/product"
import { ExtraGroupItemCart } from "@define/models/cart"
import { connect } from "react-redux"
import { moneyMask, widthWindow } from "@utils/number"
import { addCartItem, removeCartItem, updateCartItem } from "@store/actions/cart.action"
import { getProduct } from "@store/actions/product.action"
import { flatten } from "@utils/style"
import FullWidthBanner from "@components/FullWidthBanner"
import EleClock from "@components/EleClock"
import MenuOnDay from "@components/MenuOnDay"
import _ from "lodash"
import { MavelSelectionItem } from "@define/mavel"
import Alert from "@components/alert/Alert"
import { navigate } from "@utils/navigation"
import { ExtraGroup, ExtraGroupItem } from "@define/models/extra-group"
import { TouchableOpacity, ScrollView } from "react-native-gesture-handler"
import FooterPage from "@components/options/FooterPage"
import HeaderPage from "@components/options/HeaderPage"
import { MenuModel } from "@define/models/menu"
import ClockProductIcon from "@components/icons/clock-icon.icon"
import QuantityInputProductDetail from "@components/forms/QuantityInputProductDetail"
import { makeResourceUrl } from "../utils/url"
import moment from "moment"
import momentTz from "moment-timezone"

interface IProps {
  route: any
  isLoading: boolean
  productSku: ProductModel
  getProduct: (payload: any) => void
  addCartItem: (payload: any) => void
  removeCartItem: (payload: any) => void
  updateCartItem: (payload: any) => void
  menus: MenuModel[]
  utc_offset: number
  timezone: string
}

interface IState {
  productImageWidth: number
  productImageHeight: number
  selectedExtras: ExtraGroupItemCart[]
  quantity: number
  note: string
  collapsed: boolean
  activeSections: any[]
}

class ProductWeb extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const productImageWidth = SCREEN.getPercentFromWith(2.8)

    this.state = {
      productImageHeight: SCREEN.calculateHeightFromWidth(productImageWidth, 16, 10),
      productImageWidth,
      selectedExtras: [],
      quantity: 1,
      note: "",
      collapsed: widthWindow < 992 ? true : false,
      activeSections: [],
    }
  }

  componentDidMount() {
    this.props.getProduct({ productId: this.props.route.params.id })
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (widthWindow > 992) {
      if (this.props.productSku !== prevProps.productSku) {
        this.setState({
          activeSections: [...prevState.activeSections, ...this.props.productSku.extra_groups.map((x) => x.id)],
        })
      }
    } else {
      if (this.props.productSku !== prevProps.productSku) {
        this.setState({
          activeSections: [...prevState.activeSections, ...this.props.productSku.extra_groups.map((x, index) => index === 0 && x.id)],
        })
      }
    }
  }
  getTotalExtraPrice = (): number => {
    return this.state.selectedExtras.map((e) => e.price).reduce((cv, pv) => cv + pv, 0)
  }

  getProductPrice = (): number => {
    return this.props.productSku.price * this.state.quantity
  }

  getTotalPrice = (): number => {
    return this.getProductPrice() + this.getTotalExtraPrice()
  }

  getSelectItems = (groupId: string): MavelSelectionItem[] => {
    return this.state.selectedExtras.filter((item) => item.groupId === groupId)
  }

  canAddToCart = (): boolean => {
    const extraGroups = this.props.productSku.extra_groups
    for (let i = 0; i < extraGroups.length; i++) {
      const extraGroup = extraGroups[i]
      if (extraGroup.min_choices > 0) {
        const length = this.state.selectedExtras.filter((e) => e.groupId === extraGroup.id).length
        if (extraGroup.min_choices > length) {
          return false
        }
      }
    }

    return true
  }

  onSelect = (extra: ExtraGroup, item: ExtraGroupItem): void => {
    let { selectedExtras } = this.state

    const extraItem: ExtraGroupItemCart = { ...item, groupId: extra.id }
    if (this.isMultiple(extra)) {
      const index = selectedExtras.findIndex((e) => e.id === item.id)
      if (index === -1) {
        if (this.valueLength(extra) + 1 > this.getMax(extra)) {
          Alert.alert("Too many selected", "", [{ text: "OK", onPress: () => {} }], { cancelable: false })
          return
        }
        selectedExtras.push(extraItem)
      } else {
        selectedExtras = selectedExtras.filter((e) => e.id !== item.id)
      }
    } else {
      selectedExtras = selectedExtras.filter((e) => e.groupId !== extra.id)
      selectedExtras.push(extraItem)
    }

    this.setState({ selectedExtras })
  }

  addToCart = (): void => {
    if (!this.canAddToCart()) {
      Alert.alert("Choose your size", "Please choose all required options", [{ text: "OK", onPress: () => console.log("OK Pressed") }], { cancelable: false })
      return
    }
    this.props.addCartItem({
      ...this.props.productSku,
      quantity: this.state.quantity,
      extrasGroupItem: this.state.selectedExtras,
      note: this.state.note,
    })
    const message = "Your item has been added to cart"
    Alert.alert("Your order", message, [
      {
        text: "Continue shopping",
        onPress: () => {},
      },
      {
        text: "Go to cart",
        onPress: () => {
          navigate("Cart")
        },
      },
    ])
  }

  getActiveMenu = (menus: MenuModel[]): any => {
    let enabledMenus = menus.filter((e) => e.is_enabled === true)
    if (enabledMenus.length > 0) {
        let start = momentTz().tz(this.props.timezone ?? "America/Denver")
        let end = momentTz().tz(this.props.timezone ?? "America/Denver")
        let now = momentTz().tz(this.props.timezone ?? "America/Denver")
        
      let filteredMenus = _.filter(menus, function(menu) {
        const menuStart = menu.start_time.split(":")
        const menuEnd = menu.end_time.split(":")

        start = start.set({ hour: menuStart[0], minute: menuStart[1] })
        end = end.set({ hour: menuEnd[0], minute: menuEnd[1] })
        return now.isBetween(start, end)
      })
      return filteredMenus[0]
    }
    return null
  }

  isRequired = (extra: ExtraGroup): boolean => {
    return extra.min_choices > 0
  }

  isMultiple = (extra: ExtraGroup): boolean => {
    return extra.max_choices > 1
  }

  getMax = (extra: ExtraGroup): number => {
    return extra.max_choices || 1000
  }

  getMin = (extra: ExtraGroup): number => {
    return extra.max_choices || 0
  }

  valueLength = (extra: ExtraGroup): number => {
    return this.state.selectedExtras.filter((e) => e.groupId === extra.id).length
  }

  isCheckedMe = (item: ExtraGroupItem): boolean => {
    if (!this.state.selectedExtras.length) {
      return false
    }

    return this.state.selectedExtras.findIndex((e) => e.id === item.id) !== -1
  }

  isDisable = (extra: ExtraGroup): boolean => {
    return this.valueLength(extra) > this.getMax(extra)
  }

  onNoteChange = (value: string): void => {
    this.setState({ note: value })
  }

  renderPriceTotal() {
    return (
      <View style={flatten([Layout.shadow, Layout.footerCustom, { alignItems: "center", paddingBottom: 15, paddingTop: 15 }])}>
        <View style={flatten([Layout.container, Layout.dFlex, styles.actionPanel])}>
          <Col size={2} style={widthWindow < 992 && styles.flex100}>
            <Text style={{ fontSize: 20, color: Color.Main }}>{this.props.productSku.name}</Text>
            <View style={flatten([Layout.flexRow, { minHeight: 28, flexWrap: "wrap" }])}>
              {this.state.selectedExtras?.map((e, indexChild) => (
                <Text key={indexChild}>
                  {indexChild > 0 && ", "}
                  {e.name}
                </Text>
              ))}
            </View>
          </Col>
          <Col size={2} style={flatten([Layout.flexRow, widthWindow < 992 && styles.flex100])}>
            <Col style={{ flexBasis: widthWindow < 992 ? "33%" : 0 }}>
              <QuantityInputProductDetail max={10} value={this.state.quantity} onChange={(val) => this.setState({ quantity: val })} />
            </Col>
            <Col style={{ flexBasis: widthWindow < 992 ? "66%" : 0 }}>
              <Button style={{ backgroundColor: Color.Primary, borderRadius: 8, paddingHorizontal: 14, justifyContent: "space-between", marginLeft: 20, width: widthWindow < 992 ? "auto" : 250 }} block onPress={this.addToCart}>
                <Text style={styles.totalPrice}>ADD TO CART</Text>
                <Text style={styles.totalPrice}>{moneyMask(this.getTotalPrice())}</Text>
              </Button>
            </Col>
          </Col>
        </View>
      </View>
    )
  }
  reformatTime = (menu: any[]): string => {
    const nowTime = momentTz().tz(this.props.timezone ?? "America/Denver")
    const time = menu.end_time
    const arrTime = time.split(":")
    nowTime.set({ hour: arrTime[0], minute: arrTime[1] })
    return nowTime.format("hh:mm a")
  }
  toggleOption = (): void => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    this.setState({ collapsed: !this.state.collapsed })
  }

  setSections = (sections: string) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    this.setState((prevState) => ({
      activeSections: prevState.activeSections.includes(sections) ? prevState.activeSections.filter((i) => i !== sections) : [sections, ...prevState.activeSections],
    }))
  }

  isSelectMe = (id: string): boolean => {
    return this.state.activeSections.includes(id)
  }

  renderContent = (extra: ExtraGroup, index: number) => {
    return (
      <View key={index} style={{ borderBottomWidth: 1, borderBottomColor: "#9d8a96", paddingTop: 24, paddingBottom: widthWindow < 992 ? 0 : 30 }}>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            paddingBottom: 20,
            alignItems: "flex-start",
          }}
          onPress={() => this.setSections(extra.id)}
        >
          <View>
            {this.isSelectMe(extra.id) ? (
              <View style={flatten([Layout.dFlex, styles.iconOuter, { backgroundColor: Color.Main }])}>
                <Icon name="angle-up" type="FontAwesome" style={{ color: "#fff" }} />
              </View>
            ) : (
              <View style={flatten([Layout.dFlex, styles.iconOuter])}>
                <Icon name="angle-down" type="FontAwesome" style={{ color: Color.Main }} />
              </View>
            )}
          </View>
          <Text style={flatten([styles.extraTitle])}> {extra.name}</Text>
          <Text style={flatten([{ paddingLeft: 5, fontSize: widthWindow < 992 ? 14 : 20, color: Color.Main }])}>{this.isRequired(extra) ? "(REQUIRED)" : "(OPTIONAL)"}</Text>
        </TouchableOpacity>
        {this.isSelectMe(extra.id) && (
          <View style={{ overflow: widthWindow < 992 ? "auto" : "visible" }}>
            <View style={flatten([{ flex: 1, flexWrap: "wrap", flexDirection: "row", paddingBottom: 15 }, widthWindow < 992 && styles.outer])}>
              {extra.items.map((item, index) => (
                <Col key={index} style={flatten([styles.rpColOuter, { padding: 7 }, extra.items.length > 3 && styles.colOuter4])}>
                  <TouchableOpacity onPress={() => this.onSelect(extra, item)} activeOpacity={0.8}>
                    <View style={flatten([styles.extraItem, this.isCheckedMe(item) && styles.active])}>
                      <View style={flatten([{ width: widthWindow < 992 ? 120 : 311, marginHorizontal: "auto", height: widthWindow < 992 ? 75 : 175, marginBottom: widthWindow < 992 ? 15 : 24 }, extra.items.length > 3 && styles.imageExtra])}>
                        <Image source={item.image_url ? { uri: makeResourceUrl(item.image_url) } : require("@assets/images/item.png")} style={{ height: "100%" }} />
                      </View>
                      <View style={{ height: 83, justifyContent: "center" }}>
                        <Text style={flatten([{ fontSize: widthWindow < 992 ? 18 : 22 }, this.isCheckedMe(item) && styles.activeTitle])} numberOfLines={1}>
                          {item.name}
                        </Text>
                        <Text style={flatten([{ fontSize: widthWindow < 992 ? 14 : 16, marginBottom: 5 }])} numberOfLines={1}>
                          {item.description}
                        </Text>
                        <View style={flatten([Layout.flexRow, { justifyContent: "space-between" }])}>
                          <Text numberOfLines={1} style={flatten([{ fontSize: widthWindow < 992 ? 16 : 18, color: "#666666" }, this.isCheckedMe(item) && styles.activeTitle])}>
                            {item.price > 0 ? moneyMask(item.price) : "INCLUDED IN PRICE"}
                          </Text>
                          <View nativeID={"option-check"}>
                            <CheckBox style={flatten([styles.radio, this.isMultiple(extra) && styles.checkbox])} checked={this.isCheckedMe(item)} color={Color.Primary} disabled={this.isDisable(extra)} />
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </Col>
              ))}
            </View>
          </View>
        )}
      </View>
    )
  }

  render() {
    return (
      <Container style={{ backgroundColor: "#ede9eb" }}>
        <HeaderPage />
        <Loading isShow={this.props.isLoading} />
        {!this.props.isLoading && this.props.productSku && (
          <Content>
            <FullWidthBanner imageUrl={this.props.productSku?.image_url} nameProduct={this.props.productSku.name} />
            <View style={{ flex: 1, alignItems: "center", width: "100%", flexGrow: 1, paddingTop: 15 }} nativeID="product-detail">
              <View style={Layout.container}>
                <View style={styles.activityArea} nativeID="order-header">
                  <Col size={1} style={widthWindow < 992 && styles.flex100}>
                    {this.props.productSku && (
                      <View>
                        <Text style={styles.menuTitle}>{this.props.productSku.menu?.name}</Text>
                        <View style={flatten([Layout.flexRow])}>
                          <ClockProductIcon />
                          <View style={{ paddingLeft: 12 }}>
                            <Text style={{ fontSize: 20 }}>Choose your bowl</Text>
                            <Text style={{ color: Color.Main, fontSize: 20 }}>Before {this.getActiveMenu(this.props.menus) ? this.reformatTime(this.getActiveMenu(this.props.menus)) : ""}</Text>
                          </View>
                        </View>
                      </View>
                    )}
                  </Col>
                  <Col size={2} style={widthWindow < 992 && styles.flex100}>
                    <MenuOnDay utc_offset={this.props.utc_offset} timezone={this.props.timezone ?? "America/Denver"}/>
                  </Col>
                  <Col size={1}>
                    <EleClock utc_offset={this.props.utc_offset} timezone={this.props.timezone ?? "America/Denver"}/>
                  </Col>
                </View>
                {_.orderBy(this.props.productSku.extra_groups, ["sort_order"], ["desc"]).map(this.renderContent)}
                <View style={{ width: "100%", paddingTop: 24, paddingBottom: widthWindow < 992 ? 0 : 30 }}>
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      paddingBottom: 20,
                      alignItems: "flex-start",
                    }}
                    onPress={() => this.toggleOption()}
                  >
                    {this.state.collapsed ? (
                      <View style={flatten([Layout.dFlex, styles.iconOuter])}>
                        <Icon name="angle-down" type="FontAwesome" style={{ color: Color.Main }} />
                      </View>
                    ) : (
                      <View style={flatten([Layout.dFlex, styles.iconOuter, { backgroundColor: Color.Main }])}>
                        <Icon name="angle-up" type="FontAwesome" style={{ color: "#fff" }} />
                      </View>
                    )}
                    <Text style={flatten([styles.extraTitle])}>Leave Note</Text>
                    <Text style={flatten([{ paddingLeft: 5, fontSize: widthWindow < 992 ? 14 : 20, color: Color.Main }])}>(OPTIONAL)</Text>
                  </TouchableOpacity>
                  {!this.state.collapsed && <Textarea bordered underline={false} rowSpan={4} style={styles.textArea} onChangeText={this.onNoteChange} placeholder="Let us know something special (allergy, spicy,…)" placeholderTextColor="#b4b4b4" />}
                </View>
              </View>
            </View>
            {!this.props.isLoading && this.props.productSku && this.renderPriceTotal()}
            <FooterPage />
          </Content>
        )}
      </Container>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.product.isLoading,
    productSku: state.product.item,
    items: state.cart.items,
    utc_offset: state.setting.restaurant_settings.utc_offset,
    timezone: state.setting.restaurant_settings.time_zone,
    menus: state.menu.items
  }
}
export default connect(mapStateToProps, { getProduct, addCartItem, removeCartItem, updateCartItem })(ProductWeb)

const styles = StyleSheet.create({
  productInfo: {
    flexDirection: "row",
    padding: 15,
  },
  productName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
  },
  productInfoLine: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 3,
    paddingBottom: 3,
  },
  productLineText: {
    fontSize: 13,
    color: "#642A4E",
    paddingLeft: 10,
  },
  description: {
    padding: 15,
  },
  actionPanel: {
    backgroundColor: "white",
    justifyContent: "flex-end",
    padding: 15,
    flexWrap: "wrap",
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Color.Light,
    paddingLeft: 5,
    paddingRight: 5,
  },
  activityArea: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    paddingVertical: 30,
    flexGrow: 1,
  },
  menuTitle: {
    fontSize: widthWindow < 992 ? 24 : 48,
    color: Color.Main,
    marginBottom: 5,
    lineHeight: widthWindow < 992 ? 25 : 50,
  },
  extraTitle: {
    fontSize: widthWindow < 992 ? 23 : 40,
    lineHeight: widthWindow < 992 ? 24 : 42,
  },
  extraItem: {
    flex: 1,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    paddingVertical: widthWindow < 992 ? 10 : 20,
    paddingHorizontal: widthWindow < 992 ? 10 : 24,
    position: "relative",
    borderColor: "transparent",
    borderWidth: 2,
    width: "100%",
  },
  radio: {
    borderColor: "transparent",
    borderRadius: 100,
    width: widthWindow < 992 ? 20 : 32,
    height: widthWindow < 992 ? 20 : 32,
    left: "unset",
  },
  checkbox: {
    borderRadius: 2,
  },
  iconOuter: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: widthWindow < 992 ? 30 : 36,
    height: widthWindow < 992 ? 30 : 36,
    marginTop: widthWindow < 992 ? 0 : 5,
    marginRight: 12,
  },
  textArea: {
    width: "100%",
    borderStyle: "solid",
    backgroundColor: "#f4f4f4",
    borderColor: "#cbcbcb",
    fontSize: widthWindow < 992 ? 13 : 16,
    paddingVertical: 15,
    borderRadius: 4,
    fontFamily: "Fester",
  },
  active: {
    borderColor: Color.Main,
  },
  activeTitle: {
    color: Color.Main,
  },
  flex100: {
    flexBasis: "100%",
  },
  outer: {
    width: "max-content",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  rpColOuter: {
    maxWidth: widthWindow < 992 ? 200 : 374,
    flexBasis: widthWindow < 992 ? "50%" : "33%",
    width: widthWindow < 992 ? 200 : 374,
  },
  colOuter4: {
    maxWidth: widthWindow < 992 ? 200 : 281,
    flexBasis: widthWindow < 992 ? "50%" : "25%",
    width: widthWindow < 992 ? 200 : 281,
  },
  imageExtra: {
    height: widthWindow < 992 ? 75 : 126,
    width: widthWindow < 992 ? 120 : 217,
  },
})

import { ActionType } from "@define/action"
import { call, put } from "redux-saga/effects"
import product from "@apis/product"
import { navigate, replace } from "../../utils/navigation"
import menuApi from "@apis/menu"
import { isActive } from "@utils/time"
import setting from "@apis/setting"

export function* getProduct(action: ActionType) {
  const { productId } = action.payload
  try {
    const response = yield call(product.getProduct, productId)
    const responseSv = yield call(product.getServingProducts)
    const lengthArray = responseSv.data.result.items?.length
    const responseMenu = yield call(menuApi.getMenus)
    const responseSetting = yield call(setting.getSettings)
    const utc_offset = responseSetting.data.result.restaurant_settings.utc_offset
    const timezone = responseSetting.data.result.restaurant_settings.time_zone
    const lengthArrayMenu = responseMenu.data.result.items?.filter((e) => e.is_enabled === true && isActive(e, utc_offset, timezone))?.length
    if (lengthArray === 0 || lengthArrayMenu === 0) {
      replace("OrderNow")
    }
    yield put({ type: "GET_PRODUCT_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_PRODUCT_FAILED", payload: error })
  }
}

export function* getServingProducts(action: ActionType) {
  try {
    const response = yield call(product.getServingProducts)
    const lengthArray = response.data.result.items.length
    const responseMenu = yield call(menuApi.getMenus)
    const responseSetting = yield call(setting.getSettings)
    const utc_offset = responseSetting.data.result.restaurant_settings.utc_offset
    const timezone = responseSetting.data.result.restaurant_settings.time_zone
    const lengthArrayMenu = responseMenu.data.result.items?.filter((e) => e.is_enabled === true && isActive(e, utc_offset, timezone))?.length

    if (lengthArray === 1 && lengthArrayMenu > 0) {
      replace("Product", { id: response.data.result.items[0].id })
    }
    yield put({ type: "GET_SERVING_PRODUCTS_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_SERVING_PRODUCTS_FAILED", payload: error })
  }
}

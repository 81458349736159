import React from "react"
import { Text } from "react-native"
import { Button, Icon } from "native-base"
import { goBack, canGoBack, toRoot } from "@utils/navigation"
import { Color } from "@theme/color"

const BackButton = () => {
  const canBack = canGoBack()

  const goBackAction = (): void => {
    if (canBack) {
      goBack()
    } else {
      toRoot()
    }
  }

  return (
    <Button onPress={goBackAction} transparent style={{ marginRight: 10 }}>
      <Icon name="ios-arrow-back" style={{ color: Color.Primary, width: 14, margin: 0 }} />
      <Text style={{ color: Color.Primary, fontFamily: "Fester" }}>{canBack ? "Back" : "Home"}</Text>
    </Button>
  )
}

export default BackButton

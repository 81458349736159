import { Dimensions } from "react-native"

export const pad2 = (number: number): string => {
  return (number < 10 ? "0" : "") + number
}

export const moneyMask = (amount: any, currency = "USD"): string => {
  if (!amount) {
    return "0.00"
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  })

  return formatter.format(amount)
}

export const formatPhoneNumber = (str: string): string => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "")

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3]
  }

  return null
}
export const widthWindow = Dimensions.get("window").width

import React from "react"
import { Alert as NativeAlert, StyleSheet, Platform, Text } from "react-native"
import { View, Button } from "native-base"
import { Color } from "@theme/color"
import { flatten } from "@utils/style"

interface IState {
  settings: any
}

var _alertInstance: Alert

class Alert extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      settings: null,
    }
  }

  static alert(
    title = "",
    desc = "",
    buttons = [
      {
        text: "Ok",
        onPress: () => {},
      },
    ],
    options = { cancelable: false }
  ) {
    if (Platform.OS === "web") {
      _alertInstance.setState({
        settings: { title, desc, buttons, options },
      })
    } else {
      NativeAlert.alert(title, desc, buttons, options)
    }
  }

  ref = null

  componentDidMount() {
    _alertInstance = this
  }
 
  onSelect(f: any) {
    this.setState(
      {
        settings: null,
      },
      () => {
        if (f) {
          f()
        }
      }
    )
  }
 showButton(number:any,text:any,counts:any){
   if( text =='Continue shopping'){
    return(
      <Text style={flatten([styles.textButon,{backgroundColor:'#edeaeb',color:Color.Purple}])}>{text}</Text>
    );
   }else{
     return(
      <Text style={styles.textButon}>{text}</Text>
     )
   }
  }
  render() {
    const number = 1;
    return (
      this.state.settings && (
        <View style={styles.wrapper}>
          <View style={flatten([styles.container,{}])}>
            {!!this.state.settings.title && (
              <View style={styles.header}>
                <Text style={styles.titleAlert}>{this.state.settings.title}</Text>
              </View>
            )}
            <View style={styles.body}>
              <Text style={{fontWeight:'600'}}>{this.state.settings.desc}</Text>
            </View>
            <View style={styles.actions}>
              {this.state.settings.buttons.map((button: any, index: number) => (
                <Button key={Number.toString()} block small disabled={button.disabled} onPress={() => this.onSelect(button.onPress)} style={styles.button}>
                   {this.showButton(index,button.text,button.counts)}
                </Button>
              ))}
            </View>
          </View>
        </View>
      )
    )
  }
}

export default Alert

const styles = StyleSheet.create({
  textButon:{ 
    fontFamily:"Fester",
    width:150,
    color: '#ffffff',
    borderRadius: 6,
    backgroundColor:Color.Main,
    fontSize:14, height:36,
    padding: 9,
    textAlign:'center'
  },
  button: {
      marginLeft: 10,
      backgroundColor:"#ffffff"
    },
  titleAlert:{
    fontFamily:"Fester",
    backgroundColor:Color.Primary,
    color:"#ffffff",
    fontSize:20,
    fontWeight: "600",
    padding: 0,
    paddingTop:15,
    paddingBottom:15,
    borderTopLeftRadius:10,
    borderTopRightRadius:10,
    textAlign:'center',
  },
  wrapper: {
    position: "absolute",
    // width: "100vw",
    // height: "100vh",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 9999999,
  },
  container: {
    minWidth: 300,
    maxWidth: 800,
    padding:0,
    backgroundColor: "#FFF",
    borderRadius: 10,
    borderColor: "#DDD",
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: "#DDD",
    padding: 0,
  },
  body: {
    padding: 25,
    textAlign:'center',
    fontWeight:500,

  },
  actions: {
    paddingHorizontal: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom:25,
    paddingTop:0,
  },
  
})

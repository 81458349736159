import { ActionType } from '@define/action';

const initState = {
    isLoading: false,
    items: [],
    error: "",
    products: []
}

const menuReducer = (state = initState, { type, payload }: ActionType) => {
    switch (type) {
        case "GET_MENUS":
            return {
                ...state,
                isLoading: true,
                error: ""
            };
        case "GET_MENUS_SUCCESS":
            return {
                ...state,
                ...payload,
                isLoading: false,
                error: ""
            };
        case "GET_MENUS_FAILED":
            return {
                ...state,
                isLoading: false,
                error: ""
            };
        case "GET_MENU_PRODUCTS":
            return {
                ...state,
                isLoading: true,
                error: ""
            };
        case "GET_MENU_PRODUCTS_SUCCESS":
            return {
                ...state,
                products: payload.items,
                isLoading: false,
                error: ""
            };
        case "GET_MENU_PRODUCTS_FAILED":
            return {
                ...state,
                isLoading: false,
                error: ""
            };
        default:
            return state
    }
}

export default menuReducer
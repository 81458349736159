import { ActionType } from '@define/action';

export const addCartItem = (payload: ActionType) => ({
    type: "ADD_CART_ITEM",
    payload,
})

export const removeCartItem = (payload: ActionType) => ({
    type: "REMOVE_CART_ITEM",
    payload,
})

export const updateCartItem = (payload: ActionType) => ({
    type: "UPDATE_CART_ITEM",
    payload,
})

export const emptyCart = (payload: ActionType) => ({
    type: 'EMPTY_CART',
    payload
})

export default {
    addCartItem,
    removeCartItem,
    updateCartItem,
    emptyCart
}

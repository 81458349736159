import { all, takeLatest } from "redux-saga/effects"
import { getMenuProducts, getMenus } from "./menu.saga"
import { getProduct, getServingProducts } from "./product.saga"
import { createOrder, getOrder, pickupOrder, chargeOrder } from "./order.saga"
import { getSettings } from "./setting.saga"

const sagas = function* () {
  yield all([takeLatest("GET_SERVING_PRODUCTS", getServingProducts)])
  yield all([takeLatest("GET_MENUS", getMenus)])
  yield all([takeLatest("GET_MENU_PRODUCTS", getMenuProducts)])
  yield all([takeLatest("GET_PRODUCT", getProduct)])
  yield all([takeLatest("CREATE_ORDER", createOrder)])
  yield all([takeLatest("GET_ORDER", getOrder)])
  yield all([takeLatest("CHARGE_ORDER", chargeOrder)])
  yield all([takeLatest("PICKUP_ORDER", pickupOrder)])
  yield all([takeLatest("GET_SETTINGS", getSettings)])
}
export default sagas

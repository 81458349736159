import { ActionType } from "@define/action"
import { CartItem } from "@define/models/cart"

interface CartState {
  isLoading: boolean
  items: CartItem[]
}

const initState: CartState = {
  items: [],
  isLoading: false
}

const addCartItem = (items: CartItem[], item: CartItem) => {
  const index = items.findIndex((it) => it.id === item.id)
  if (index === -1) {
    items.push(item)
  } else {
    if (checkIsAdded(items[index], item)) {
      items[index].quantity += item.quantity
    } else {
      items = [...items, item]
    }
  }
  return items
}

const updateCartItem = (items: CartItem[], id: string, quantity: number) => {
  const index = items.findIndex((item) => item.id === id)
  if (index !== -1) {
    if (quantity) {
      items[index].quantity = quantity
    }
  }
  return items
}

const checkIsAdded = (currentItem: CartItem, newItem: CartItem): boolean => {
  const currentExtraGroupIds = currentItem.extrasGroupItem.map(e => e.id)
  const newExtraGroupIds = newItem.extrasGroupItem.map(e => e.id)

  if (currentExtraGroupIds.length !== newExtraGroupIds.length) {
    return false;
  }

  for (let i = 0; i < currentExtraGroupIds.length; i++) {
    if (!newExtraGroupIds.includes(currentExtraGroupIds[i])) {
      return false;
    }
  }

  return true;
}

const cartReducer = (state = initState, { type, payload }: ActionType) => {
  switch (type) {
    case "ADD_CART_ITEM":
      return {
        ...state,
        items: [...addCartItem(state.items, payload)],
      }
    case "UPDATE_CART_ITEM":
      return {
        ...state,
        items: [...updateCartItem(state.items, payload.id, payload.quantity)],
      }
    case "REMOVE_CART_ITEM":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
      }
    case "EMPTY_CART":
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cartReducer


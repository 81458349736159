import React from "react"
import { Header } from "native-base"
import { Layout } from "@theme/layout"
import { StyleSheet, View } from "react-native"
import { flatten } from "@utils/style"

const AppHeader = ({ children }: any) => {
  return (
    <Header style={StyleSheet.flatten([Layout.Header, Layout.shadow])}>
      <View style={flatten([Layout.container, { flex: 1, flexDirection: "row" }])}>{children}</View>
    </Header>
  )
}

export default AppHeader

import { ActionType } from '@define/action';

const initData = {
    isLoading: false,
    loggedIn: false,
    user: null,
}

const authReducer = (state = initData, { type, payload }: ActionType) => {
    switch (type) {
        case 'LOGIN':
            return {
                ...state,
                isLoading: true,
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLoading: false,
                loggedIn: true,
                user: payload.data,
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                isLoading: false,
                isLoginFailed: true,
            }
        default:
            return state
    }
}

export default authReducer;

import React from "react"
import { Text, Left, Body, Right, Container, Title, Content, List, ListItem, View, Button } from "native-base"
import BackButton from "@components/BackButton"
import AppHeader from "@components/options/HeaderPage"
import { StyleSheet, TouchableOpacity } from "react-native"
import FoodItem from "@components/FoodItem"
import QuantityInput from "@components/forms/QuantityInput"
import { Color } from "@theme/color"
import { navigate } from "@utils/navigation"
import { CartItem } from "@define/models/cart"
import { connect } from "react-redux"
import { updateCartItem, removeCartItem } from "@store/actions/cart.action"
import { moneyMask } from "@utils/number"
import { flatten } from "@utils/style"
import { Layout } from "@theme/layout"
import * as _ from "lodash"
import FooterPage from "@components/options/FooterPage"

interface IProps {
  items: CartItem[]
  updateCartItem: (payload: any) => void
  removeCartItem: (payload: any) => void
}

interface IState {}

class CartScreen extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
  }

  onChangeQuantity = (id: string, quantity: number): void => {
    this.props.updateCartItem({ id, quantity })
  }

  removeItem = (id: string): void => {
    this.props.removeCartItem({ id })
  }

  totalPrice = (): number => {
    return this.props.items.map((e) => this.getItemPrice(e)).reduce((cv, pv) => cv + pv, 0)
  }

  totalProduct = (): number => {
    return this.props.items.map((e) => e.quantity).reduce((cv, pv) => cv + pv, 0)
  }

  getItemPrice = (item: CartItem): number => {
    return (item.price + item.extrasGroupItem.map((e) => e.price).reduce((cv, pv) => cv + pv, 0)) * item.quantity
  }

  getItemExtras = (item: CartItem): any => {
    return _.map(item.extrasGroupItem, "name").join(", ")
  }

  canCheckout = (): boolean => {
    return this.props.items.length > 0
  }

  render() {
    return (
      <Container style={{ backgroundColor: Color.Purple_r, marginTop: 80, paddingTop: 50 }}>
        <AppHeader>
          <Left style={{ maxWidth: 100 }}>
            <BackButton />
          </Left>
          <Body style={{ alignItems: "center" }}>
            <Title style={Layout.title}>Cart</Title>
          </Body>
          <Right style={{ maxWidth: 100 }}></Right>
        </AppHeader>
        <Content style={{ backgroundColor: Color.Purple_r }}>
          <View style={flatten([Layout.container, { minHeight: 500 }])}>
            {this.props.items.length > 0 && (
              <List>
                <ListItem itemHeader first>
                  <Text style={styles.titleCart}>Shopping Cart</Text>
                  <View style={flatten([Layout.container, { display: "block" }])}>
                    <Text style={flatten([styles.totalPrice, { fontWeight: 600, fontSize: 14, left: "auto", bottom: 0, right: 0 }])}>Total:</Text>
                    <Text style={flatten([styles.totalPrice, { fontFamily: "Fester", textAlign: "right", fontWeight: 600, right: 0, fontSize: 28, color: "#000000" }])}>{moneyMask(this.totalPrice())}</Text>
                  </View>
                </ListItem>
                {this.props.items.map((product, index) => (
                  <ListItem key={index} style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Left>
                      <FoodItem title={product.name} description={this.getItemExtras(product)} price={this.getItemPrice(product)} url={product.image_url} />
                    </Left>
                    <Right>
                      <QuantityInput max={10} value={product.quantity} onChange={(val) => this.onChangeQuantity(product.id, val)} />
                      <View style={{ flexDirection: "row", justifyContent: "center", paddingTop: 5 }}>
                        <TouchableOpacity onPress={() => this.removeItem(product.id)}>
                          <Text style={styles.remove}>Remove</Text>
                        </TouchableOpacity>
                      </View>
                    </Right>
                  </ListItem>
                ))}
              </List>
            )}
            {this.props.items.length === 0 && (
              <View style={{ flex: 1, flexGrow: 1, height: "80vh", justifyContent: "center", alignItems: "center", marginTop: 30 }}>
                <Text style={{ fontSize: 18, fontWeight: "500", color: "#888" }}>Your cart is empty</Text>
              </View>
            )}
          </View>
          <View style={flatten([Layout.shadow, Layout.footerCustom, { alignItems: "center", paddingBottom: 15, paddingTop: 15 }])}>
            <View style={flatten([Layout.container, Layout.dFlex, { justifyContent: "flex-end" }])}>
              <Text style={flatten([styles.totalPrice, { fontWeight: 600, fontSize: 14 }])}>Total:</Text>
              <Text style={flatten([styles.totalPrice, { fontFamily: "Fester", textAlign: "right", fontWeight: 600, left: 40, fontSize: 28, color: "#000000" }])}>{moneyMask(this.totalPrice())}</Text>
              <Button style={flatten([styles.btnCheckout, { backgroundColor: Color.Primary, marginRight: 15 }])} small onPress={() => navigate("Checkout")} disabled={!this.canCheckout()}>
                <Text style={{ fontFamily: "Fester", fontSize: 14, fontWeight: "600", paddingLeft: 62, paddingRight: 62 }}>CHECKOUT</Text>
              </Button>
            </View>
          </View>
          <FooterPage />
        </Content>
      </Container>
    )
  }
}

export default connect(
  (state) => {
    return {
      items: state.cart.items,
    }
  },
  { updateCartItem, removeCartItem }
)(CartScreen)

const styles = StyleSheet.create({
  titleCart: {
    fontFamily: "Fester",
    fontSize: 30,
    color: "#651d47",
  },
  remove: {
    fontFamily: "Fester",
    color: "#651d47",
    fontSize: 16,
    width: "auto",
    textAlign: "center",
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Color.Primary,
    paddingLeft: 0,
    paddingRight: 0,
    position: "absolute",
    left: 0,
    alignSelf: "flex-end",
    lineHeight: "1",
  },
  listActionItem: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
    borderTopWidth: 1,
  },
  btnCheckout: {
    borderRadius: 5,
    height: 38,
    paddingTop: 3,
    backgroundColor: "#651d47",
  },
})

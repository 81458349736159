import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import HomeScreen from "@screens/home.screen"
import OrderDetailScreen from "@screens/order-detail.web"
import CartScreen from "@screens/cart.screen"
import CheckoutScreen from "@screens/checkout.screen"
import MenuScreen from "@screens/menu.screen"
import PaymentScreen from "@screens/payment.screen"
import ThankYouScreen from "@screens/thankyou.screen"
import orderNowScreen from "@screens/order-now.screen"
import ProductWeb from "@screens/product.web"
import { connect } from "react-redux"
import { View, ActivityIndicator, Dimensions, StyleSheet } from "react-native"
import { Color } from "@theme/color"

const Stack = createStackNavigator()

const RootNavigator = ({ isLoading }: any) => {
  return (
    <View style={{ flex: 1 }}>
      {isLoading && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={Color.Main} />
        </View>
      )}
      <Stack.Navigator initialRouteName={"Home"} screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="OrderNow" component={orderNowScreen} />
        <Stack.Screen name="OrderDetail" component={OrderDetailScreen} />
        <Stack.Screen name="Product" component={ProductWeb} />
        <Stack.Screen name="Cart" component={CartScreen} />
        <Stack.Screen name="Checkout" component={CheckoutScreen} />
        <Stack.Screen name="Menu" component={MenuScreen} />
        <Stack.Screen name="Payment" component={PaymentScreen} />
        <Stack.Screen name="ThankYou" component={ThankYouScreen} />
      </Stack.Navigator>
    </View>
  )
}

export default connect((state: any) => ({ isLoading: state.setting.isLoading }))(RootNavigator)

const styles = StyleSheet.create({
  loading: {
    height: Dimensions.get("window").height,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "absolute",
    width: "100%",
    left: 0,
    top: 0,
    zIndex: 9,
  },
})

import React, { useState } from "react"
import { Text, View } from "react-native"
import { Left, Body, Right, Container, Title, Content, Form, Label, Button } from "native-base"
import AppHeader from "@components/layouts/AppHeader"
import { StyleSheet } from "react-native"
import { Color } from "@theme/color"
import { CartItem } from "@define/models/cart"
import { connect } from "react-redux"
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { flatten } from "@utils/style"
import { Layout } from "@theme/layout"
import { moneyMask, widthWindow } from "@utils/number"
import { chargeOrder } from "../store/actions/order.action"
import { STRIPE_KEY } from "../config/env.prod"
import Loading from "@components/Loading"
import { getOrder } from "@store/actions/order.action"
import { OrderModel } from "@define/models/order"
import FooterPage from "@components/options/FooterPage"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}

export const CheckoutForm = ({ tax, totalPrice, chargeOrderAction, preStep }: any) => {
  const [error, setError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError(null)
    }
  }

  // Handle form submission.
  const handleSubmit = async () => {
    const card = elements?.getElement(CardElement)
    const result = await stripe?.createToken(card)
    if (result?.error) {
      setError(result.error.message)
    } else {
      setError(null)
      chargeOrderAction(result?.token)
    }
  }

  return (
    <Form style={{ width: "100%" }}>
      <View>
        <Label style={{ marginBottom: 10, fontFamily: "Fester", color: "#666", fontSize: 14 }}>Credit or Debit card</Label>
        <View style={styles.creditInput}>
          <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        </View>
        <View>
          <Text>{error}</Text>
        </View>
        <View style={{ height: 30 }} />
        <View style={{ alignItems: "flex-end", marginBottom: 25 }}>
          <View style={flatten([Layout.flexRow, styles.total])}>
            <Text style={flatten([styles.summaryText])}>Subtotal:</Text>
            <Text style={{ fontSize: widthWindow < 992 ? 16 : 24 }}>{moneyMask(totalPrice)}</Text>
          </View>
          <View style={flatten([Layout.flexRow, styles.total])}>
            <Text style={flatten([styles.summaryText])}>Tax ({tax * 100}%):</Text>
            <Text style={{ fontSize: widthWindow < 992 ? 16 : 24 }}>{moneyMask(tax * totalPrice)}</Text>
          </View>
          <View style={flatten([Layout.flexRow, styles.total])}>
            <Text style={flatten([styles.summaryText, { color: Color.Main }])}>Total:</Text>
            <Text style={{ fontSize: widthWindow < 992 ? 20 : 32 }}>{moneyMask(totalPrice + tax * totalPrice)}</Text>
          </View>
        </View>
        <View style={flatten([Layout.flexRow, { justifyContent: "space-between" }])}>
          <Button style={flatten([styles.button, { backgroundColor: "#ede9eb" }])} onPress={preStep}>
            <Text style={{ color: Color.Main, fontSize: 18 }}>Back to step 1</Text>
          </Button>
          <Button style={styles.button} onPress={handleSubmit}>
            <Text style={{ color: "white", fontSize: 18 }}>PAY</Text>
          </Button>
        </View>
      </View>
    </Form>
  )
}

interface IProps {
  items: CartItem[]
  route: any
  isLoading: boolean
  order: OrderModel
  chargeOrder: (payload: any) => void
  getOrder: (payload: any) => void
}

interface IState {}

class PaymentScreen extends React.Component<IProps, IState> {
  stripe: any

  constructor(props: any) {
    super(props)
    this.initStripe()
  }

  componentDidMount() {
    if (this.props.route.params && this.props.route.params.id) {
      this.props.getOrder({ id: this.props.route.params.id })
    }
  }

  initStripe = () => {
    this.stripe = loadStripe(STRIPE_KEY)
  }

  totalPrice = (): number => {
    return this.props.items.map((e) => this.getItemPrice(e)).reduce((cv, pv) => cv + pv, 0)
  }

  getItemPrice = (item: CartItem): number => {
    return (item.price + item.extrasGroupItem.map((e) => e.price).reduce((cv, pv) => cv + pv, 0)) * item.quantity
  }
  handleChargeOrder = (token: any): void => {
    this.props.chargeOrder({ id: this.props.route.params.id, stripe_token: token.id })
  }

  render() {
    return (
      <Container>
        <AppHeader>
          <Left style={{ maxWidth: 100 }}></Left>
          <Body style={{ alignItems: "center" }}>
            <Title style={Layout.title}>Payment</Title>
          </Body>
          <Right style={{ maxWidth: 100 }}></Right>
        </AppHeader>
        <Loading isShow={this.props.isLoading} />
        <Content>
          <View>
            <Elements stripe={this.stripe}>
              <CheckoutForm totalPrice={this.props.order?.total} chargeOrderAction={this.handleChargeOrder} />
            </Elements>
          </View>
          <FooterPage />
        </Content>
      </Container>
    )
  }
}

export default connect(
  (state: any) => {
    return {
      order: state.order.currentOrder,
      isLoading: state.order.isLoading,
      items: state.cart.items,
    }
  },
  { getOrder, chargeOrder }
)(PaymentScreen)

const styles = StyleSheet.create({
  itemHeaderText: {
    fontSize: 18,
    fontWeight: "500",
    paddingLeft: 5,
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Color.Primary,
    paddingLeft: 15,
    paddingRight: 15,
  },
  listActionItem: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
  },
  Container: {
    backgroundColor: "#F7F7F7",
  },
  orderInfo: {
    backgroundColor: "#fff",
    borderRadius: 5,
    maxWidth: 493,
    shadowRadius: 5,
    margin: 15,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  radio: {
    borderRadius: 20,
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: "#000",
    justifyContent: "flex-end",
    alignItems: "center",
    lineHeight: 20,
  },
  button: {
    backgroundColor: Color.Main,
    height: 48,
    justifyContent: "center",
    marginTop: 30,
    borderRadius: 8,
    width: widthWindow < 992 ? "48%" : 233,
  },
  label: {
    fontWeight: "300",
    paddingLeft: 10,
  },
  bordered: {
    borderWidth: 1,
    borderColor: "#AFAFAF",
    borderRadius: 5,
    fontSize: 16,
    height: 40,
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    color: "#313131",
  },
  checked: {
    borderColor: "#D91703",
  },
  creditInput: {
    borderWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    borderColor: "#e1e1e1",
    paddingLeft: 5,
  },
  summary: {
    flexDirection: "row",
    marginVertical: 6,
    justifyContent: "flex-end",
    flex: 1,
  },
  total: {
    justifyContent: "space-between",
    width: widthWindow < 992 ? "100%" : "50%",
    marginBottom: 5,
  },
  summaryText: {
    color: "#666",
    fontSize: widthWindow < 992 ? 14 : 20,
  },
})

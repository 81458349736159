import React from "react"
import ClockIcon from "@assets/svg/clock-icon.svg"
import { IconProps } from "@define/icon"
import { StyleSheet } from "react-native"
import { Color } from "@theme/color"
import { Colors } from "react-native/Libraries/NewAppScreen"

const ClockProductIcon = (props: IconProps) => {
  const style = StyleSheet.flatten([props.style, { fill: Color.Main }])
  return <ClockIcon width={props.width || 48} height={props.height || 48} style={style} />
}

export default ClockProductIcon

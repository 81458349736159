export const Color = {
    Primary: '#642A4E',
    Purple:'#651d47',
    Link: '#1474DA',
    Purple_r:"#edeaeb",
    Light: '#FFFFFF',
    Dark: '#333333',
    Main: '#642A4E',
    Yellow: 'rgba(255,204,34,0.5)',

    Danger: '#D91703',

    Header: '#144E8C',

};

import { ActionType } from "@define/action"
import menuApi from "@apis/menu"
import { call, put } from "redux-saga/effects"
import { navigate } from "@utils/navigation"
import { replace } from "../../utils/navigation"

export function* getMenus(action: ActionType) {
  try {
    const response = yield call(menuApi.getMenus)
    yield put({ type: "GET_MENUS_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_MENUS_FAILED", payload: error })
  }
}
export function* getMenuProducts(action: ActionType) {
  const { menuId } = action.payload
  try {
    const response = yield call(menuApi.getMenuProducts, menuId)
    const lengthArray = response.data.result.items.length
    if (lengthArray === 1) {
      replace("Product", { id: response.data.result.items[0].id })
    }
    yield put({ type: "GET_MENU_PRODUCTS_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_MENU_PRODUCTS_FAILED", payload: error })
  }
}

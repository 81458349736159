import React from "react"
import { Text, StyleSheet } from "react-native"
import { View } from "native-base"
import { Layout } from "@theme/layout"
import { Color } from "@theme/color"
import ImageDefault from "@components/options/imageDefault"
import { moneyMask, widthWindow } from "@utils/number"
import { OrderItemExtra } from "@define/models/order"

interface IProps {
  title: string
  description?: string
  price: number
  url: any
  number?: number
  extras?: OrderItemExtra[]
}

const OrderItem = (props: IProps) => {
  return (
    <View style={StyleSheet.flatten([Layout.flexRow, { flex: 1, alignItems: "flex-start" }])}>
      <View style={{ width: widthWindow < 992 ? 60 : 120 }}>
        <ImageDefault image_url={props.url} height={widthWindow < 992 ? 60 : 120} width={"100%"} />
      </View>
      <View style={{ paddingLeft: widthWindow < 992 ? 16 : 30, width: widthWindow < 992 ? "76%" : "85%" }}>
        <Text style={{ paddingBottom: 3, fontSize: widthWindow < 992 ? 18 : 28 }}>{props.title}</Text>
        {props.extras && (
          <Text style={{ fontSize: widthWindow < 992 ? 14 : 20, color: "#666", marginBottom: 8 }} numberOfLines={widthWindow < 992 ? 3 : 12} ellipsizeMode="tail">
            {props.extras.map((e) => e.name + " " + (e.price === 0 ? "INCLUDED IN PRICE" : "$" + e.price)).join(" | ")}
          </Text>
        )}
        <Text style={{ color: Color.Main, fontSize: widthWindow < 992 ? 14 : 20 }}>{moneyMask(props.price)}</Text>
      </View>
    </View>
  )
}
export default OrderItem

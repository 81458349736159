import React from "react"
import { Image } from "react-native"
import { makeResourceUrl } from "@utils/url"

interface IProps {
  image_url: any
  width?: number
  height?: number
}

const ImageDefault = (props: IProps) => {
  return <Image source={props.image_url ? { uri: makeResourceUrl(props.image_url) } : require("@assets/images/no-image.jpg")} style={{ width: props.width || 50, height: props.height || 50 }} />
}
export default ImageDefault

import React from "react"
import { View, Text, ImageBackground, StyleSheet } from "react-native"
import { makeResourceUrl } from "@utils/url"
import { widthWindow } from "@utils/number"

interface IProps {
  imageUrl: any
  nameProduct: string
}

const FullWidthBanner = (props: IProps) => {
  return (
    <View nativeID="banner-page" style={{ height: widthWindow < 992 ? 290 : 400 }}>
      <ImageBackground source={props.imageUrl ? { uri: makeResourceUrl(props.imageUrl) } : require("@assets/images/hero-image.jpg")} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
        <Text style={{ color: "#fff", fontSize: widthWindow < 992 ? 32 : 50, maxWidth: 800, paddingTop: 45, textAlign: "center" }}>“{props.nameProduct}”</Text>
      </ImageBackground>
    </View>
  )
}

export default FullWidthBanner
const styles = StyleSheet.create({})

import React, { Component } from "react"
import { View } from "native-base"
import { Layout } from "@theme/layout"
import { StyleSheet, Text } from "react-native"
import LineFoodIcon from "@components/icons/line-food.icon"

interface IProps {
  title: string
}

export class TitleBold extends Component<IProps> {
  render() {
    return (
      <View style={Layout.flexRow}>
        <LineFoodIcon width={23} />
        <Text style={{ fontSize: 18, fontWeight: "700", paddingLeft: 8, textTransform: "uppercase" }}>{this.props.title}</Text>
      </View>
    )
  }
}

export default TitleBold

import React, { useState, useEffect } from "react"
import { StyleSheet, Text, View, TextInput } from "react-native"
import { FormField } from "../../theme/form"
import { ERROR_CODES } from "../../constants/validator"
import { flatten } from "@utils/style"
import { formatPhoneNumber } from "@utils/number"

const TextInputControl = (props: any) => {
  const { handler, touched, hasError, meta, getError } = props
  const handlerObj = handler()

  const isError = (): any => {
    if (!touched) {
      return null
    }
    for (let i = 0; i < ERROR_CODES.length; i++) {
      if (hasError(ERROR_CODES[i])) {
        return FormField.Error
      }
    }
    return null
  }

  const isDisabled = (): any => {
    return meta.disabled ? FormField.Disabled : null
  }

  const style = (): any => {
    return flatten([FormField.Input, isDisabled(), isError()])
  }

  const value = (): string => {
    if (meta.type === "phone") {
      return formatPhoneNumber(handlerObj.value + "")
    }
    return handlerObj.value + ""
  }

  return (
    <View style={{ width: "100%" }}>
      <View style={style()}>
        <TextInput style={styles.textInput} {...handlerObj} placeholder={meta.placeholder} onChangeText={handlerObj.onChange} editable={meta.disabled ? false : true} value={value()} keyboardType={meta.keyboardType} />
      </View>
      {touched && meta.messages && (
        <View>
          {Object.keys(meta.messages).map((message, index) => {
            if (hasError(message)) {
              return (
                <Text key={index} style={styles.Text}>
                  {meta.messages[message]}
                </Text>
              )
            }
            return null
          })}
        </View>
      )}
    </View>
  )
}

export default TextInputControl

const styles = StyleSheet.create({
  Text: {
    color: "red",
  },
  textInput: {
    outline: 'none !important',
    letterSpacing: 1,
    fontFamily: 'Fester',
    borderWidth: 0,
    width: "100%",
    height: 35,
    paddingLeft: 15,
    paddingRight: 15,
  },
})

import React from "react"
import { TouchableOpacity, Text, StyleSheet } from "react-native"
import { Badge, View } from "native-base"
import { Color, Layout } from "@theme"
import { navigate } from "@utils/navigation"
import { connect } from "react-redux"
import CartIcon from "./icons/cart.icon"

const CartButton = ({ quantity }: any) => {
  const toCartScreen = () => {
    navigate("Cart")
  }

  return (
    <TouchableOpacity activeOpacity={1} onPress={toCartScreen}>
      <View style={StyleSheet.flatten([Layout.dFlex, Styles.cartMini])} nativeID="cart-mini">
        <CartIcon />
        <Badge style={Styles.numberMini}>
          <Text style={{ fontSize: 12, lineHeight: 12, width: 20, color: Color.Primary }}>{quantity}</Text>
        </Badge>
      </View>
    </TouchableOpacity>
  )
}
export default connect((state: any) => {
  return {
    quantity: state.cart.items.map((item: any) => item.quantity).reduce((cv: number, pv: number) => cv + pv, 0),
  }
})(CartButton)

export const Styles = StyleSheet.create({
  cartMini: {
    justifyContent: "flex-end",
    width: 52,
    height: 35,
    borderLeftWidth: 1,
    borderLeftColor: "#fff",
    alignItems: "flex-end",
  },
  numberMini: {
    backgroundColor: "transparent",
    alignItems: "center",
    paddingTop: 10,
    width: 20,
    position: "absolute",
    right: -5,
    top: 7,
  },
})

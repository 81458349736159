import React from "react"
import { Image } from "react-native"
import { Text, Left, Body, Right, Container, Content, View, Button, Icon } from "native-base"
import BackButton from "@components/BackButton"
import AppHeader from "@components/options/HeaderPage"
import { StyleSheet } from "react-native"
import { connect } from "react-redux"
import { Layout } from "@theme/layout"
import { navigate } from "@utils/navigation"
import FooterPage from "@components/options/FooterPage"
import { Color } from "@theme/"

interface IProps {}

interface IState {}

class ThankYouScreen extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <Container>
        <AppHeader>
          <Left style={{ maxWidth: 100 }}>
            <BackButton />
          </Left>
          <Body style={{ alignItems: "center" }}>
            <Image style={Layout.headerLogo} source={require("@assets/images/logo-header.png")} />
          </Body>
          <Right style={{ maxWidth: 100 }}></Right>
        </AppHeader>
        <Content style={{backgroundColor:Color.Purple_r,paddingTop:130}}>
          <View style={[ Layout.container, { justifyContent: "center", alignItems: "center", borderRadius: 3, overflow: "hidden",backgroundColor:"#edeaeb" }]}>
            <Image style={{ width: 350,height:250, marginVertical: 20 }} source={require("@assets/images/thanks-you.png")} />
            <Text style={StyleSheet.flatten([styles.title, { fontWeight:'600'}])}>Thank You</Text>
            <Text style={styles.specialFont}>Your payment was processed successfully. Hope you enjoy you meal.</Text>
            <Button style={styles.btnHome} onPress={() => navigate("Home")}>
              <Text style={StyleSheet.flatten([styles.specialFont, { fontSize: 15,color:"#fff",fontFamily:"Fester" }])}>BACK TO HOME</Text>
            </Button>
          </View>
          <FooterPage />
        </Content>
      </Container>
    )
  }
}

export default connect()(ThankYouScreen)

const styles = StyleSheet.create({
  title:{
    fontFamily: "Fester",
    fontSize:35,
    color:"#7e2559",
    marginBottom:5,
  },
  specialFont: {
    fontFamily: "Roboto",
    color:"#161616",
    fontSize: 16,
    textTransform:"none",
    fontWeight:"500",
    textAlign:'center',
    paddingRight:15,
    paddingLeft:15,
  },
  bgBot: {
    backgroundColor: "#fff",
    paddingVertical: 30,
    paddingHorizontal: 15,
    textAlign: "center",
  },
  btnHome: {
    backgroundColor: "#7e2559",
    alignSelf: "center",
    minWidth: 200,
    justifyContent: "center",
    borderRadius: 5,
    marginTop: 30,
    marginBottom: 40,
    height:38,
    paddingTop:3 ,
    paddingBottom:6,
  },
})

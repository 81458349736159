import { pad2 } from "./number"
import { MenuModel } from "@define/models/menu"
import moment from "moment"
import momentTz from "moment-timezone"

export const times = () => {
  const hours = []
  for (let i = 0; i <= 24; i++) {
    hours.push(pad2(i))
  }
  const minutes = []
  for (let i = 0; i <= 59; i++) {
    minutes.push(pad2(i))
  }
  return {
    hours,
    minutes,
  }
}
export const isActive = (menu: MenuModel, timezoneOffset = 7, timezone = "America/Denver"): boolean => {
  const now = momentTz().tz(timezone)
  let start = momentTz().tz(timezone)
  let end = momentTz().tz(timezone)
  const menuStart = menu.start_time.split(":")
  const menuEnd = menu.end_time.split(":")

  start = start.set({ hour: menuStart[0], minute: menuStart[1] })
  end = end.set({ hour: menuEnd[0], minute: menuEnd[1] })

  return now.isBetween(start, end)
}

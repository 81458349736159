import React, { useEffect, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { MenuModel } from "@define/models/menu"
import { connect } from "react-redux"
import { getMenus } from "@store/actions/menu.action"
import moment from "moment"
import momentTz from "moment-timezone"
import { flatten } from "@utils/style"
import _ from "lodash"

interface IProps {
  isLoading: boolean
  menus: MenuModel[]
  getMenus: () => void
  utc_offset: number
  timezone: string
}

const MenuOnDay = (props: IProps) => {
  const [now, setNow] = useState(momentTz().tz(props.timezone ?? "America/Denver"))

  useEffect(() => {
    setNow(now.add(1, "seconds"))
    props.getMenus()
  }, [])

  const isActive = (menu: MenuModel): boolean => {
    // let start = moment().utcOffset(props.utc_offset)
    // let end = moment().utcOffset(props.utc_offset)
    let start = momentTz().tz(props.timezone ?? "America/Denver")
    let end = momentTz().tz(props.timezone ?? "America/Denver")

    const menuStart = menu.start_time.split(":")
    const menuEnd = menu.end_time.split(":")

    start = start.set({ hour: menuStart[0], minute: menuStart[1] })
    end = end.set({ hour: menuEnd[0], minute: menuEnd[1] })

    return now.isBetween(start, end)
  }

  const reformat = (menu: MenuModel): string => {
    // const nowTime = moment().utcOffset(props.utc_offset)
    const nowTime = momentTz().tz(props.timezone ?? "America/Denver")
    const time = isActive(menu) ? menu.end_time : menu.start_time
    const arrTime = time.split(":")
    nowTime.set({ hour: arrTime[0], minute: arrTime[1] })
    return nowTime.format("hh:mm a")
  }
  return (
    <View style={styles.container}>
      {_.orderBy(props.menus, ["start_time"], ["desc"])
        .filter((e) => e.is_enabled === true)
        .map((menu, index) => (
          <View key={index} style={flatten([styles.area, isActive(menu) && styles.isActive])}>
            <Text style={flatten([styles.text, { fontSize: 18 }])}>{menu.name}</Text>
            <Text style={styles.text}>{!isActive(menu) ? "From " + reformat(menu) : "Before " + reformat(menu)}</Text>
          </View>
        ))}
    </View>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.menu.isLoading,
    menus: state.menu.items.reverse(),
  }
}
export default connect(mapStateToProps, { getMenus })(MenuOnDay)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "center",
  },
  area: {
    borderColor: "#360a24",
    width: "100%",
    maxWidth: 130,
    flexGrow: 1,
    padding: 7,
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#661c48",
    opacity: 0.2,
    borderWidth: 1,
    margin: 8,
  },
  isActive: {
    opacity: 1,
  },
  text: {
    color: "#fff",
    fontSize: 14,
  },
})

import React from "react"
import { View, Icon } from "native-base"
import { StyleSheet, TextInput, TouchableHighlight } from "react-native"
import { Color } from "@theme/color"
import { flatten } from "@utils/style"

interface IProps {
  value: number
  min?: number
  max?: number
  onChange: (val: number) => void
}

const QuantityInput = (props: IProps) => {
  const onChangeText = (text: string): void => {
    const value = Number(text)
    if (value > getMaxVal()) {
      return props.onChange(getMaxVal())
    }
    if (value < getMinVal()) {
      return props.onChange(getMinVal())
    }
    props.onChange(value)
  }

  const value = (): string => {
    return props.value ? props.value + "" : "0"
  }

  const increase = (): void => {
    props.onChange(parseInt(props.value) + 1)
  }
  const reduction = (): void => {
    props.onChange(parseInt(props.value) - 1)
  }

  const canIncrease = (): boolean => {
    return Number(props.value) < getMaxVal()
  }

  const canReduction = (): boolean => {
    return Number(props.value) > getMinVal()
  }

  const getMinVal = (): number => {
    return props.min || 1
  }

  const getMaxVal = (): number => {
    return props.max || 1000
  }

  const buttonIconReStyle = flatten([styles.buttonIcon, !canReduction() && styles.disabledButton])
  const buttonIconInStyle = flatten([styles.buttonIcon, !canIncrease() && styles.disabledButton])

  const buttonReStyle = flatten([styles.button, !canReduction() && styles.disabledButton])
  const buttonInStyle = flatten([styles.button, !canIncrease() && styles.disabledButton])

  return (
    <View style={styles.container}>
      <TouchableHighlight style={buttonReStyle} onPress={reduction} underlayColor="#fafafa" activeOpacity={0.8} disabled={!canReduction()}>
        <Icon name="remove" type="Ionicons" style={buttonIconReStyle} />
      </TouchableHighlight>
      <TextInput style={styles.input} value={value()} onChangeText={onChangeText} keyboardType="number-pad" />
      <TouchableHighlight style={buttonInStyle} onPress={increase} underlayColor="#fafafa" activeOpacity={0.8} disabled={!canIncrease()}>
        <Icon name="add" type="Ionicons" style={buttonIconInStyle} />
      </TouchableHighlight>
    </View>
  )
}

export default QuantityInput

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 5,
    border:'none',
    backgroundColor:"#ffffff",
    width: 24,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1,
    color:Color.Purple,
  },
  buttonIcon: {
    fontSize: 20,
    color: Color.Purple,
  },
  disabledButton: {
    color: "#c8bcc3",
  },
  input: {
    fontFamily:"Fester",
    width: 25,
    borderRadius: 5,
    height: 26,
    fontSize:25,
    marginLeft: 10,
    marginRight: 5,
    border:'none',
    textAlign: "center",
    fontWeight:600,
    color: Color.Purple,
  },
})

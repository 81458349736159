import Api from "@config/axios"

function getMenus() {
    return Api.get('menus')
}
function getMenuProducts(menuId: string) {
    return Api.get(`menus/${menuId}/products`)
}
export default {
    getMenus,
    getMenuProducts
}
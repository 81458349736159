import { Platform } from 'react-native'

export function loadState() {
    if (Platform.OS === 'web') {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    }
    return null
}

export function saveState(state) {
    if (Platform.OS === 'web') {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('state', serializedState);
        } catch {
            // ignore write errors
        }
    }
    return null
};
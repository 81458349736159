import { StyleSheet } from "react-native";

export const FormField = StyleSheet.create({
  Input: {
    borderWidth: 1,
    borderColor: "#AFAFAF",
    borderRadius: 5,
    height: 40,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  NeedTouch: {
    backgroundColor: '#C2D1B2',
    borderColor: '#693'
  },
  Error: {
    backgroundColor: '#F9DDCD',
    borderColor: '#F36F24'
  },
  Disabled: {
    backgroundColor: '#f3f3f3',
    borderColor: '#e1e1e1'
  }
})

import React, { Component } from "react"
import { Col, Icon, Row, View } from "native-base"
import { Layout } from "@theme/layout"
import { Image, StyleSheet, Text, Linking, Dimensions } from "react-native"
import { flatten } from "@utils/style"
import { Color } from "@theme/"
export class FooterPage extends Component<any, any> {
  render() {
    return (
      <View style={Styles.bgFooter} nativeID="footer-main">
        <View style={flatten([Layout.container, { flex: 1 }])}>
          <Row style={{ paddingBottom: 20, flexWrap: "wrap" }}>
            <Col style={flatten([Styles.col])}>
              <Text style={{ fontSize: 18, color: "#be0020", fontWeight: "500", marginBottom: 10 }}>1 Mongolian BBQ</Text>
              <Text style={flatten([Styles.textGray, Styles.lineHeight])}>We are driven by uncompromising freshness and quality, gracious hospitality and a growing list of restaurants.</Text>
            </Col>
            <Col style={flatten([Styles.col])}>
              <Text style={flatten([Styles.white, Styles.title])}>Location</Text>
              <View style={flatten([Layout.flexRow, { alignItems: undefined, paddingBottom: 10 }])}>
                <Icon type={"MaterialCommunityIcons"} name={"map-marker"} style={flatten([Styles.icon])} />
                <Text style={flatten([Styles.textWhite, Styles.lineHeight])}>8249 W OVERLAND RD, STE 180 BOISE, ID83709</Text>
              </View>
              <View style={flatten([Layout.flexRow, { alignItems: undefined, paddingBottom: 10 }])}>
                <Icon type={"MaterialCommunityIcons"} name={"phone"} style={flatten([Styles.icon])} />
                <Text style={flatten([Styles.textWhite])}>208 321 1240</Text>
              </View>
            </Col>
            <Col style={flatten([Styles.col])}>
              <Text style={flatten([Styles.white, Styles.title])}>Open hours</Text>
              <View style={flatten([Layout.flexRow, { alignItems: undefined, paddingBottom: 10 }])}>
                <Icon type={"MaterialCommunityIcons"} name={"clock"} style={flatten([Styles.icon])} />
                <View>
                  <Text style={flatten([Styles.textWhite, { paddingBottom: 10 }])}>
                    <Text style={flatten([Styles.red])}>Mon-thur: </Text>
                    10:45 - 21:00
                  </Text>
                  <Text style={flatten([Styles.textWhite, { paddingBottom: 10 }])}>
                    <Text style={flatten([Styles.red])}>Fri-sat: </Text>
                    10:45 - 22:00
                  </Text>
                  <Text style={flatten([Styles.textWhite, { paddingBottom: 10 }])}>
                    <Text style={flatten([Styles.red])}>Sun: </Text>
                    12:00 - 20:00
                  </Text>
                </View>
              </View>
            </Col>
            <Col style={flatten([Styles.col])}>
              <Text style={flatten([Styles.white, Styles.title])}>Payments</Text>
              <Image style={Styles.credit} source={require("@assets/images/payment-logo.png")} />
            </Col>
          </Row>
        </View>
        <View style={flatten([Styles.copyRight, Layout.dFlex])}>
          <Text style={flatten([Styles.textWhite, { fontSize: 12 }])}>#1 Mongolian BBQ @ {new Date().getFullYear()}. </Text>
          <Text style={flatten([Styles.red, { fontSize: 12 }])} onPress={() => Linking.openURL("http://1mongolianbbq.com/privacy-policy/")}>
            {" "}
            Privacy Policy
          </Text>
        </View>
      </View>
    )
  }
}

export default FooterPage
export const Styles = StyleSheet.create({
  bgFooter: {
    paddingTop: 20,
    backgroundColor: "#4e1333",
  },
  col: {
    paddingHorizontal: 15,
    minWidth: 255,
    marginBottom: 10,
  },
  lineHeight: {
    lineHeight: 20,
  },
  textWhite: {
    color: "white",
  },
  textGray: {
    color: "#adbdbc"
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  white: {
    color: "#fff"
  },
  red: {
    color: "#be0020",
  },
  icon: {
    fontSize: 16,
    marginRight: 5,
    color: "#be0020",
  },
  credit: {
    height: 30,
    width: 190,
  },
  copyRight: {
    backgroundColor: "#373b46",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
})

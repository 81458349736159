import { ActionType } from '@define/action';

export const getSettings = () => ({
  type: "GET_SETTINGS"
})

export const getSettingsSuccess = (payload: ActionType) => ({
  type: "GET_SETTINGS_SUCCESS",
  payload,
})

export const getSettingsFailed = (payload: ActionType) => ({
  type: "GET_SETTINGS_FAILED",
  payload,
})

export default {
  getSettings,
  getSettingsSuccess,
  getSettingsFailed
}
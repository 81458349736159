import { ActionType } from '@define/action';

export const createOrder = (payload: ActionType) => ({
    type: "CREATE_ORDER",
    payload,
})

export const createOrderSuccess = (payload: ActionType) => ({
    type: "CREATE_ORDER_SUCCESS",
    payload,
})

export const createOrderFailed = (payload: ActionType) => ({
    type: "CREATE_ORDER_FAILED",
    payload,
})

export const getOrder = (payload: ActionType) => ({
    type: "GET_ORDER",
    payload,
})

export const getOrderSuccess = (payload: ActionType) => ({
    type: "GET_ORDER_SUCCESS",
    payload,
})

export const getOrderFailed = (payload: ActionType) => ({
    type: "GET_ORDER_FAILED",
    payload,
})

export const chargeOrder = (payload: ActionType) => ({
    type: "CHARGE_ORDER",
    payload,
})

export const chargeOrderSuccess = (payload: ActionType) => ({
    type: "CHARGE_ORDER_SUCCESS",
    payload,
})

export const chargeOrderFailed = (payload: ActionType) => ({
    type: "CHARGE_ORDER_FAILED",
    payload,
})

export const pickupOrder = (payload: ActionType) => ({
    type: "PICKUP_ORDER",
    payload,
})

export const pickupOrderSuccess = (payload: ActionType) => ({
    type: "PICKUP_ORDER_SUCCESS",
    payload,
})

export const pickupOrderFailed = (payload: ActionType) => ({
    type: "PICKUP_ORDER_FAILED",
    payload,
})


export default {
    createOrder,
    createOrderSuccess,
    createOrderFailed,
    getOrder,
    getOrderSuccess,
    getOrderFailed,
    chargeOrder,
    chargeOrderSuccess,
    chargeOrderFailed,
    pickupOrder,
    pickupOrderSuccess,
    pickupOrderFailed
}

import React from "react"
import { Container, Left, Right, Content, Icon, Button, List, ListItem, Textarea, Col } from "native-base"
import { Color } from "@theme/color"
import { StyleSheet, View, Text } from "react-native"
import { Layout } from "@theme/layout"
import OrderItem from "@components/OrderItem"
import { OrderModel } from "@define/models/order"
import { getOrder, pickupOrder } from "@store/actions/order.action"
import Spinner from "react-native-loading-spinner-overlay"
import moment from "moment"
import momentTz from "moment-timezone"
import { flatten } from "@utils/style"
import { connect } from "react-redux"
import { moneyMask, widthWindow } from "@utils/number"
import Loading from "@components/Loading"
import FooterPage from "@components/options/FooterPage"
import HeaderPage from "@components/options/HeaderPage"
import { wrap } from "lodash"

interface IProps {
  route: any
  isLoading: boolean
  order: OrderModel
  getOrder: (payload: any) => void
  pickupOrder: (payload: any) => void
  utc_offset: number
  timezone: string
}

interface IState {
  note: string
}

class OrderDetailScreen extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      note: "",
    }
  }

  componentDidMount() {
    if (this.props.route.params && this.props.route.params.id) {
      this.props.getOrder({ id: this.props.route.params.id })
    }
  }
  getDurationTime = (): string => {
    // const createdAt = moment(this.props.order.created_at).utcOffset(this.props.utc_offset)
    const createdAt = momentTz(this.props.order.created_at).tz(this.props.timezone ?? "America/Denver")
    const match = this.props.order.pick_up_time.split(":")
    const durationAt = createdAt.clone().set({ hour: parseInt(match[0]), minute: parseInt(match[1]) })
    // const verb = moment().utcOffset(this.props.utc_offset).isAfter(durationAt) ? "Picked" : "Will pick"
    const verb = momentTz().tz(this.props.timezone ?? "America/Denver").isAfter(durationAt) ? "Picked" : "Will pick"
    return verb + " " + durationAt.fromNow()
  }

  pickupOrder = (): void => {
    this.props.pickupOrder({ id: this.props.order.id, status: "ARRIVED", note: this.state.note })
  }

  canPickUp = (): boolean => {
    return this.props.order.is_paid
  }

  onNoteChange = (value: string): void => {
    this.setState({ note: value })
  }
  render() {
    const { order } = this.props
    return (
      <Container style={{ backgroundColor: "#ede9eb" }}>
        <Spinner visible={this.props.isLoading} textContent="" />
        <HeaderPage />
        <Loading isShow={this.props.isLoading} />
        {!this.props.isLoading && this.props.order && (
          <Content>
            <View style={flatten([Layout.container, { marginTop: widthWindow < 992 ? 80 : 140, paddingBottom: 50 }])}>
              <View style={{ padding: 20 }}>
                <Text style={Layout.sectionTitle}>Order Detail</Text>
                <View style={flatten([Layout.shadow, Layout.dFlex, styles.orderInfo])}>
                  <Col style={{ flexBasis: widthWindow < 992 ? "100%" : 0 }}>
                    <Text style={styles.name}>{order.customer_name}</Text>
                    <View style={flatten([Layout.flexRow, { paddingTop: 15 }])}>
                      <Icon name={"phone-in-talk"} type={"MaterialIcons"} style={styles.icon} />
                      <Text style={styles.text}>{order.customer_phone}</Text>
                    </View>
                  </Col>
                  <Col style={{ position: "unset", flexBasis: widthWindow < 992 ? "100%" : 0 }}>
                    <View style={flatten([Layout.dFlex, styles.code, widthWindow < 992 && styles.rpCode])}>
                      <Text style={{ fontSize: 14, paddingRight: widthWindow < 992 ? 0 : 25 }}>Order ID:</Text>
                      <Text style={{ color: Color.Main, fontSize: 20 }}>{order.order_number}</Text>
                    </View>
                    <View style={flatten([Layout.flexRow, { justifyContent: widthWindow < 992 ? "flex-start" : "flex-end", paddingTop: widthWindow < 992 ? 0 : 15, flexWrap: "wrap", marginHorizontal: -15 }])}>
                      <View style={flatten([Layout.dFlex, { paddingHorizontal: 15 }])}>
                        <Icon name={"clock"} type={"MaterialCommunityIcons"} style={styles.icon} />
                        {/* <Text style={styles.text}>Time ORDERED {moment(order.created_at).utcOffset(this.props.utc_offset).format("HH:mm")}</Text> */}
                        <Text style={styles.text}>Time ORDERED {momentTz(order.created_at).tz(this.props.timezone ?? "America/Denver").format("HH:mm")}</Text>
                      </View>
                      {this.props.order.pick_up_status === "ARRIVED" && (
                        <View style={flatten([Layout.dFlex, { paddingHorizontal: 15 }])}>
                          <Icon name={"cube"} style={styles.icon} />
                          <Text style={styles.text}>{this.getDurationTime()}</Text>
                        </View>
                      )}
                    </View>
                  </Col>
                </View>
              </View>
              <View style={{ paddingBottom: 30 }}>
                <View
                  style={{
                    paddingBottom: 10,
                    marginHorizontal: 15,
                  }}
                ></View>
                <View style={flatten([Layout.shadow, styles.orderDetail])}>
                  <List>
                    {order.items.map((product, index) => (
                      <ListItem key={index} style={{ marginLeft: 0, paddingTop: 25, paddingBottom: 25 }}>
                        <Left>
                          <OrderItem title={product.product_sku.name} description={""} price={product.total} url={product.product_sku.image_url} extras={product.extras} />
                        </Left>
                        <Right>
                          <Text style={{ color: Color.Main, fontSize: widthWindow < 992 ? 16 : 24 }}>{product.quantity}</Text>
                        </Right>
                      </ListItem>
                    ))}
                  </List>
                  <View style={{ paddingVertical: 25 }}>
                    <Text style={flatten([{ fontSize: 14, color: "#666666" }])}>Leave Note</Text>
                    <Textarea bordered underline={false} rowSpan={4} style={styles.textArea} onChangeText={this.onNoteChange} placeholder="Let us know something special (allergy, spicy,…)" placeholderTextColor="#b4b4b4" />
                  </View>
                  <View style={{ alignItems: "flex-end" }}>
                    <View style={flatten([Layout.flexRow, styles.total])}>
                      <Text style={flatten([styles.bold])}>Subtotal:</Text>
                      <Text style={{ fontSize: widthWindow < 992 ? 16 : 24 }}>{moneyMask(order.sub_total)}</Text>
                    </View>
                    <View style={flatten([Layout.flexRow, styles.total])}>
                      <Text style={flatten([styles.bold])}>Tax(6%):</Text>
                      <Text style={{ fontSize: widthWindow < 992 ? 16 : 24 }}>{moneyMask(order.tax)}</Text>
                    </View>
                    <View style={flatten([Layout.flexRow, styles.total])}>
                      <Text style={flatten([styles.bold, { color: Color.Main }])}>Total:</Text>
                      <Text style={{ fontSize: widthWindow < 992 ? 20 : 32 }}>{moneyMask(order.total)}</Text>
                    </View>
                  </View>
                </View>
                {this.props.order.pick_up_status !== "ARRIVED" && (
                  <Button style={styles.button} onPress={this.pickupOrder} disabled={!this.canPickUp()}>
                    <Text style={flatten([{ color: "white", fontSize: 18 }])}>I’VE ARRIVED</Text>
                  </Button>
                )}
              </View>
            </View>
            <FooterPage />
          </Content>
        )}
      </Container>
    )
  }
}

export default connect(
  (state: any) => ({
    order: state.order.currentOrder,
    isLoading: state.order.isLoading,
    utc_offset: state.setting.restaurant_settings.utc_offset,
    timezone: state.setting.restaurant_settings.time_zone,
  }),
  { getOrder, pickupOrder }
)(OrderDetailScreen)

const styles = StyleSheet.create({
  orderInfo: {
    backgroundColor: "#fff",
    borderRadius: 16,
    padding: 24,
    shadowRadius: 5,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  name: {
    fontSize: widthWindow < 992 ? 20 : 28,
    marginBottom: 2,
  },
  icon: {
    fontSize: 16,
    color: Color.Main,
    marginRight: 9,
  },
  code: {
    backgroundColor: "#ede9eb",
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 8,
    alignSelf: widthWindow < 992 ? "flex-start" : "flex-end",
    marginTop: widthWindow < 992 ? 16 : 0,
  },
  button: {
    backgroundColor: Color.Main,
    alignSelf: "flex-end",
    height: 48,
    width: 253,
    justifyContent: "center",
    marginTop: 30,
    marginHorizontal: 15,
    borderRadius: 6,
  },
  text: {
    fontSize: 16,
    color: "#666",
    textTransform: "uppercase",
  },
  orderDetail: {
    marginHorizontal: 15,
    borderRadius: 16,
    overflow: "hidden",
    backgroundColor: "white",
    padding: 24,
  },
  textArea: {
    width: "100%",
    borderStyle: "solid",
    backgroundColor: "#f4f4f4",
    borderColor: "#cbcbcb",
    fontSize: widthWindow < 992 ? 13 : 16,
    padding: 15,
    borderRadius: 4,
    fontFamily: "Fester",
  },
  total: {
    justifyContent: "space-between",
    width: widthWindow < 992 ? "100%" : "50%",
    marginBottom: 5,
  },
  bold: {
    color: "#666",
    fontSize: widthWindow < 992 ? 14 : 20,
  },
  rpCode: {
    position: "absolute",
    right: 15,
    top: 15,
    maxWidth: 105,
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 0,
  },
})

import React from "react"

import { Col, Container, Content, Form, Label, Radio, Row, Button } from "native-base"
import { StyleSheet, View, Text } from "react-native"
import { Layout } from "@theme/layout"
import { Color } from "@theme/color"
import moment from "moment"
import momentTz from "moment-timezone"
import { connect } from "react-redux"
import _ from "lodash"
import { FormBuilder, Validators, FieldGroup, FieldControl } from "react-reactive-form"
import TextInputControl from "@components/forms/TextInputControl"
import { checkoutValidateMessages } from "@constants/validator"
import { CartItem } from "@define/models/cart"
import { createOrder } from "../store/actions/order.action"
import Spinner from "react-native-loading-spinner-overlay"
import { flatten } from "@utils/style"
import TimePicker from "react-time-picker"
import Alert from "../components/alert/Alert"
import Loading from "@components/Loading"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CheckoutForm } from "./payment.screen"
import { STRIPE_KEY } from "../config/env.prod"
import { navigate } from "../utils/navigation"
import FooterPage from "@components/options/FooterPage"
import HeaderPage from "@components/options/HeaderPage"

import { widthWindow, moneyMask } from "@utils/number"

interface IProps {
  items: CartItem[]
  restaurant_settings: any
  isLoading: boolean
  createOrder: (payload: any) => void
}

interface IState {
  pick_up_type: string
  pick_up_time: string
  sched_time: string
  note: string
  onStep: number
}

class CheckoutScreen extends React.Component<IProps, IState> {
  private stripe = loadStripe(STRIPE_KEY)

  form = FormBuilder.group({
    customer_name: ["", Validators.required],
    customer_phone: ["", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)]],
    note: "",
  })

  constructor(props: IProps) {
    super(props)
    this.state = {
      pick_up_type: "ASAP",
      pick_up_time: "",
      sched_time: "",
      note: "",
      onStep: 1,
    }
  }

  componentDidMount() {
    if (!this.props.items || !this.props.items.length) {
      Alert.alert("Warning", "Your cart is empty", [
        {
          text: "Back to home",
          onPress: () => {
            navigate("Home")
          },
        },
      ])
    }
    this.setState({
      // pick_up_time: moment().utcOffset(this.props.restaurant_settings.utc_offset).format("HH:mm"),
      // sched_time: moment().utcOffset(this.props.restaurant_settings.utc_offset).format("HH:mm"),
      pick_up_time: momentTz().tz(this.props.restaurant_settings.time_zone).format("HH:mm"),
      sched_time: momentTz().tz(this.props.restaurant_settings.time_zone).format("HH:mm"),
    })
    this.onChangePickUpType()
    this.form.valueChanges.subscribe(() => this.forceUpdate())
    this.setState({ onStep: 1 })
  }

  componentWillUnmount() {
    this.form.valueChanges.unsubscribe(() => {})
  }

  handleInputChange = (name: string, value: string): void => {
    this.setState({ [name]: value })
    if (name === "pick_up_type") {
      this.onChangePickUpType()
    }
    this.forceUpdate()
  }

  onChangePickUpType = (): void => {
    const time = this.getAsSoonAsPossibleTime()
    this.setState({ pick_up_time: time, sched_time: time }, () => this.forceUpdate())
  }

  getAsSoonAsPossibleTime = (): any => {
    const maxCookingTime = _.maxBy(this.props.items, (p) => p.average_cooking_time)
    // return moment().utcOffset(this.props.restaurant_settings.utc_offset).add(maxCookingTime, "minutes").format("HH:mm")
    return momentTz().tz(this.props.restaurant_settings.time_zone).add(maxCookingTime, "minutes").format("HH:mm")
  }

  totalPrice = (): number => {
    return this.props.items.map((e) => this.getItemPrice(e)).reduce((cv, pv) => cv + pv, 0)
  }

  getItemPrice = (item: CartItem): number => {
    return (item.price + item.extrasGroupItem.map((e) => e.price).reduce((cv, pv) => cv + pv, 0)) * item.quantity
  }

  nextStep = () => {
    if (this.canCheckout()) {
      this.setState({ onStep: 2 })
    }
  }

  preStep = () => {
    this.setState({ onStep: 1 })
  }

  canCheckout = (): boolean => {
    if (this.form.invalid) {
      return false
    }

    if (this.state.pick_up_type === "ASAP") {
      return true
    }
    if (!this.state.sched_time) {
      return false
    }

    if (this.state.sched_time === this.state.pick_up_time) {
      return false
    }

    const pickupTime = this.state.pick_up_time.split(":")
    const schedTime = this.state.sched_time.split(":")
    if (parseInt(pickupTime[0]) > parseInt(schedTime[0])) {
      return false
    }

    const diff = parseInt(schedTime[0]) - parseInt(pickupTime[0])
    if (diff === 0 && parseInt(pickupTime[1]) > parseInt(schedTime[1])) {
      return false
    }

    return true
  }

  checkout = (token: any): void => {
    if (!this.canCheckout()) {
      Alert.alert("Warn", "Your information is invalid", [{ text: "OK", onPress: () => {} }], { cancelable: false })
      return
    }

    const data = {
      ...this.state,
      ...this.form.value,
      customer_phone: _.replace(this.form.value.customer_phone, /-/g, ""),
      pick_up_time: this.state.pick_up_type === "ASAP" ? this.state.pick_up_time : this.state.sched_time,
      items: this.props.items.map((item) => ({
        product_sku_id: item.id,
        quantity: item.quantity,
        extra_item_ids: item.extrasGroupItem.map((e) => e.id),
        note: item.note,
      })),
      stripe_token: token.id,
    }
    this.props.createOrder(data)
  }

  renderStep2 = () => {
    return (
      <View style={flatten([Layout.shadow, styles.orderInfo, styles.col, { position: "relative" }])}>
        {this.state.onStep !== 2 && <View style={styles.disabledOverlay}></View>}
        <Text style={styles.stepHeader}>Step 2: Payment</Text>
        <Elements stripe={this.stripe}>
          <CheckoutForm tax={this.props.restaurant_settings.tax} totalPrice={this.totalPrice()} chargeOrderAction={this.checkout} preStep={this.preStep} />
        </Elements>
      </View>
    )
  }

  render() {
    return (
      <Container style={styles.Container}>
        <Spinner visible={this.props.isLoading} textContent="" />
        <HeaderPage />
        <Loading isShow={this.props.isLoading} />
        <Content>
          <View style={flatten([Layout.container, { marginTop: widthWindow < 992 ? 80 : 140, paddingBottom: 50 }])}>
            <Text style={flatten([Layout.sectionTitle, { paddingLeft: widthWindow < 992 ? 15 : 0 }])}>Check out</Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around", position: "relative" }}>
              <View style={flatten([Layout.shadow, styles.orderInfo, styles.col])}>
                {this.state.onStep !== 1 && <View style={styles.disabledOverlay}></View>}
                <Text style={styles.stepHeader}>Step 1: Check out</Text>
                <Form style={{ width: "100%" }}>
                  <View>
                    <FieldGroup
                      control={this.form}
                      render={({ get, invalid }) => (
                        <View>
                          <Row style={{ marginBottom: 20 }}>
                            <Col style={{ paddingRight: 8 }}>
                              <Label style={styles.formLabel}>Name</Label>
                              <FieldControl name="customer_name" render={TextInputControl} meta={{ messages: checkoutValidateMessages.name, placeholder: "Enter your name" }} />
                            </Col>
                            <Col style={{ paddingLeft: 8 }}>
                              <Label style={styles.formLabel}>Phone</Label>
                              <FieldControl name="customer_phone" render={TextInputControl} meta={{ keyboardType: "phone-pad", messages: checkoutValidateMessages.phone, type: "phone", placeholder: "Enter your phone number" }} />
                            </Col>
                          </Row>
                        </View>
                      )}
                    />
                    <Row>
                      <Radio selected={this.state.pick_up_type === "ASAP"} style={[styles.radio, this.state.pick_up_type === "ASAP" && styles.checked]} onPress={() => this.handleInputChange("pick_up_type", "ASAP")} selectedColor={Color.Main} />
                      <Label style={styles.label}>ASAP at {this.state.pick_up_time && this.state.pick_up_time}</Label>
                    </Row>
                    <View style={{ marginTop: 15, marginBottom: 50 }}>
                      <View style={flatten([Layout.dFlex, { width: 160, height: 20, justifyContent: "flex-start" }])}>
                        <Radio selected={this.state.pick_up_type === "SCHEDULED"} style={flatten([styles.radio, this.state.pick_up_type === "SCHEDULED" && styles.checked])} onPress={() => this.handleInputChange("pick_up_type", "SCHEDULED")} sselectedColor={Color.Main} />
                        <Label style={styles.label}>Schedule</Label>
                      </View>
                      <View style={{ marginTop: 8, maxWidth: 210 }}>
                        <TimePicker onChange={(val: any) => this.handleInputChange("sched_time", val)} value={this.state.sched_time} format="HH:mm" disableClock={true} disabled={this.state.pick_up_type === "ASAP"} minTime={this.state.pick_up_time} />
                      </View>
                    </View>
                    <View style={flatten([Layout.flexRow, { justifyContent: "space-between", paddingTop: 30, borderTopWidth: 1, borderTopColor: "#9d8a96" }])}>
                      <View style={flatten([Layout.flexRow, { width: widthWindow < 992 && "48%" }])}>
                        <Text style={{ color: Color.Main, fontSize: 20 }}>Total: </Text>
                        <Text style={{ fontSize: widthWindow < 992 ? 24 : 32, paddingLeft: 8 }}>{moneyMask(this.totalPrice())}</Text>
                      </View>
                      <Button style={flatten([styles.button, { opacity: !this.canCheckout() ? 0.5 : 1 }])} onPress={this.nextStep} disabled={this.canCheckout() ? false : true}>
                        <Text style={{ color: "white", fontSize: 18 }}>OK</Text>
                      </Button>
                    </View>
                  </View>
                </Form>
              </View>
              {this.renderStep2()}
            </View>
          </View>
          <FooterPage />
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state.cart.items,
    isLoading: state.order.isLoading,
    restaurant_settings: state.setting.restaurant_settings,
  }
}
export default connect(mapStateToProps, { createOrder })(CheckoutScreen)

const styles = StyleSheet.create({
  Container: {
    backgroundColor: "#ede9eb",
  },
  col: {
    marginBottom: 20,
    width: widthWindow < 992 ? "95%" : "49%",
    minWidth: 350,
    alignSelf: "center",
    minHeight: 417,
  },
  orderInfo: {
    backgroundColor: "#fff",
    borderRadius: 16,
    shadowRadius: 16,
    padding: 24,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  radio: {
    borderRadius: 20,
    width: 20,
    height: 20,
    borderColor: "#6f787e",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    backgroundColor: Color.Main,
    height: 48,
    justifyContent: "center",
    borderRadius: 8,
    width: widthWindow < 992 ? "48%" : 233,
  },
  formLabel: {
    color: "#666",
    fontSize: 14,
  },
  label: {
    fontWeight: "300",
    paddingLeft: 10,
  },
  checked: {
    borderColor: Color.Main,
  },
  stepHeader: {
    fontSize: widthWindow < 992 ? 20 : 28,
    marginBottom: 20,
  },
  disabledOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#e1e1e1",
    opacity: 0.5,
    zIndex: 99,
  },
})

export const getMenus = (payload: any) => ({
    type: "GET_MENUS",
    payload
});

export const getMenusSuccess = (payload: any) => ({
    type: "GET_MENUS_SUCCESS",
    payload
});

export const getMenusFailed = (payload: any) => ({
    type: "GET_MENUS_FAILED",
    payload
});
export const getMenuProducts = (payload: any) => ({
    type: "GET_MENU_PRODUCTS",
    payload
});
export const getMenuProductsSuccess = (payload: any) => ({
    type: "GET_MENU_PRODUCTS_SUCCESS",
    payload
});

export const getMenuProductsFailed = (payload: any) => ({
    type: "GET_MENU_PRODUCTS_FAILED",
    payload
});
export default {
    getMenus,
    getMenusSuccess,
    getMenusFailed,
    getMenuProducts,
    getMenuProductsSuccess,
    getMenuProductsFailed,
}
export const getProduct = (payload: any) => ({
  type: "GET_PRODUCT",
  payload,
})

export const getProductSuccess = (payload: any) => ({
  type: "GET_PRODUCT_SUCCESS",
  payload,
})

export const getProductFailed = (payload: any) => ({
  type: "GET_PRODUCT_FAILED",
  payload,
})

export const getServingProducts = (payload: any) => ({
  type: "GET_SERVING_PRODUCTS",
  payload,
})

export const getServingProductsSuccess = (payload: any) => ({
  type: "GET_SERVING_PRODUCTS_SUCCESS",
  payload,
})

export const getServingProductsFailed = (payload: any) => ({
  type: "GET_SERVING_PRODUCTS_FAILED",
  payload,
})

export default {
  getProduct,
  getProductSuccess,
  getProductFailed,
  getServingProducts,
  getServingProductsSuccess,
  getServingProductsFailed,
}

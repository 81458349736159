import { ActionType } from "@define/action"

const initState = {
  isLoading: true,
  restaurant_settings: {
    tax: 0.06,
    utc_offset: 7,
  },
}

const settingReducer = (state = initState, { type, payload }: ActionType) => {
  switch (type) {
    case 'GET_SETTINGS':
      return {
        ...state,
        isLoading: true
      }
    case "GET_SETTINGS_SUCCESS":
      return {
        ...state,
        restaurant_settings: payload.restaurant_settings,
        isLoading: false
      }
    case 'GET_SETTINGS_FAILED':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default settingReducer

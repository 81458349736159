import { ActionType } from "@define/action"
import { call, put, select, delay } from "redux-saga/effects"
import setting from "@apis/setting"

export function* getSettings(action: ActionType) {
  try {
    const response = yield call(setting.getSettings)
    yield delay(1000)
    yield put({ type: "GET_SETTINGS_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_SETTINGS_FAILED", payload: error })
  }
}

export const ERROR_CODES = [
    "required",
    "max",
    "min",
    "maxLength",
    "minLength",
    "pattern",
    "email"
]

export const checkoutValidateMessages = {
    email: {
        required: 'Please enter an email address',
        email: 'Please enter a valid email address',
    },
    name: {
        required: 'Please enter your name',
        
    },
    phone: {
        required: 'Please enter your phone number',
        pattern: 'Please enter invalid phone number',
       
    }
}

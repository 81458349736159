import React from "react"
import { Container, Left, Right, Content, Icon, List, ListItem, Body, Title, View, Text } from "native-base"
import { Color } from "@theme/color"
import { StyleSheet } from "react-native"
import TitleBold from "@components/TitleBold"
import FoodItem from "@components/FoodItem"
import BackButton from "@components/BackButton"
import AppHeader from "@components/layouts/AppHeader"
import { connect } from "react-redux"
import { getMenuProducts } from "@store/actions/menu.action"
import { navigate } from "@utils/navigation"
import CartButton from "@components/CartButton"
import Loading from "@components/Loading"
import { Layout } from "../theme/layout"

interface IProps {
  route: any
  isLoading: boolean
  products: any[]
  getMenuProducts: (menuId: any) => void
}

class MenuScreen extends React.Component<IProps, any> {
  componentDidMount() {
    this.props.getMenuProducts({ menuId: this.props.route.params.id })
  }

  totalProduct = (): number => {
    return this.props.products.length
  }

  render() {
    return (
      <Container style={styles.Container}>
        <AppHeader>
          <Left style={{ maxWidth: 100 }}>
            <BackButton />
          </Left>
          <Body style={{ alignItems: "center" }}>
            <Title style={Layout.title}>Menu</Title>
          </Body>
          <Right style={{ maxWidth: 100 }}>
            <CartButton />
          </Right>
        </AppHeader>
        <Loading isShow={this.props.isLoading} />
        <Content>
          {!this.props.isLoading && (
            <View>
              {this.totalProduct() > 0 ? (
                <List style={{ paddingTop: 20 }}>
                  <ListItem itemHeader first>
                    <TitleBold title={this.totalProduct() + " items"} />
                  </ListItem>
                  {this.props.products.map((product, index) => (
                    <ListItem key={index} onPress={() => navigate("Product", { id: product.id })} style={{ backgroundColor: "white" }}>
                      <Left>
                        <FoodItem title={product.name} description={product.short_description} price={product.price} url={product.image_url} />
                      </Left>
                      <Right>
                        <Icon name={"chevron-right"} type={"MaterialCommunityIcons"} style={{ color: "#888" }} />
                      </Right>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <View style={{ flex: 1, flexGrow: 1, height: 300, justifyContent: "center", alignItems: "center", marginTop: 30 }}>
                  <Text style={{ fontSize: 18, fontWeight: "500", color: "#888" }}>Menu is empty</Text>
                </View>
              )}
            </View>
          )}
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.menu.isLoading,
    products: state.menu.products,
  }
}

export default connect(mapStateToProps, { getMenuProducts })(MenuScreen)
const styles = StyleSheet.create({
  Container: {
    backgroundColor: "#F7F7F7",
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Color.Primary,
    paddingLeft: 15,
    paddingRight: 15,
  },
  listActionItem: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
  },
})

import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { NavigationContainer } from "@react-navigation/native"
import RootNavigator from "@navigations/root.navigator"
import store from "@store/index"
import { navigationRef } from "@utils/navigation"
import { Platform } from "react-native"
import Alert from "@components/alert/Alert"
import { linking } from "@navigations/linking"
import * as Font from "expo-font"
import { getSettings } from "./src/store/actions/setting.action"

const App = () => {
  useEffect(() => {
    store.store.dispatch(getSettings())
    if (Platform.OS === "web") {
      Font.loadAsync({
        Fester: require("@assets/fonts/FesterBold.woff"),
      })
    }
  }, [])

  return (
    <Provider store={store.store}>
      <Alert />
      <NavigationContainer linking={linking} ref={navigationRef}>
        <RootNavigator />
      </NavigationContainer>
    </Provider>
  )
}

export default App

import React, { Component } from "react"
import { Badge, Body, Button, Icon, Left, Right, View, Header } from "native-base"
import { Layout } from "@theme/layout"
import { Image, StyleSheet, TouchableOpacity, Text, Linking, Dimensions } from "react-native"
import { flatten } from "lodash"
import { navigate } from "@utils/navigation"
import CartButton from "@components/CartButton"

export class HeaderPage extends Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      collapsed: true,
      width: Dimensions.get("window").width,
    }
  }
  toggleMenu = (): void => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  render() {
    return (
      <View nativeID={"header-main"}>
        {this.state.width <= 768 && !this.state.collapsed && (
          <View nativeID="bg-dark">
            <TouchableOpacity onPress={() => this.toggleMenu()}></TouchableOpacity>
          </View>
        )}
        <Header style={StyleSheet.flatten([Styles.headerOuter])} transparent>
          <Left style={{ alignSelf: "flex-start", flex: 2 }}>
            <TouchableOpacity activeOpacity={0.8} onPress={() => navigate("Home")}>
              <Image style={Styles.headerLogo} source={require("@assets/images/logo-food-order.png")} />
            </TouchableOpacity>
          </Left>
          <View style={flatten([Layout.dFlex, { justifyContent: "flex-end", flex: 3 }, this.state.width <= 768 && this.state.collapsed && Styles.right, this.state.width <= 768 && !this.state.collapsed && Styles.menuMain])} nativeID="menu-main">
            <Button style={Styles.orderNow} onPress={() => navigate("OrderNow")} bordered>
              <Text style={Styles.textMenu}>ORDER NOW</Text>
            </Button>
            <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com")}>
              <Text style={Styles.textMenu}>HOME</Text>
            </TouchableOpacity>
            <View nativeID="menu-about">
              <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com/about/")}>
                <Text style={Styles.textMenu}>ABOUT</Text>
              </TouchableOpacity>
              <View nativeID="sub-menu">
                <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com/gallery/")}>
                  <Text style={Styles.textMenu}>GALLERY</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com/about/faq")}>
                  <Text style={Styles.textMenu}>FAQ</Text>
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com/our-menu/")}>
              <Text style={Styles.textMenu}>OUR MENU</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL("http://1mongolianbbq.com/contacts")}>
              <Text style={Styles.textMenu}>CONTACT US</Text>
            </TouchableOpacity>
          </View>
          <Right style={{ flex: 0.5, alignItems: "center" }}>
            <CartButton />
            <Button transparent onPress={() => this.toggleMenu()}>
              {!this.state.collapsed ? <Icon name="close" type="FontAwesome" style={{ width: 22 }} /> : <Icon name="navicon" type="FontAwesome" style={{ width: 22 }} />}
            </Button>
          </Right>
        </Header>
      </View>
    )
  }
}
export default HeaderPage

export const Styles = StyleSheet.create({
  headerLogo: {
    width: 403,
    height: 120,
  },
  headerOuter: {
    borderBottomWidth: 0,
    height: 80,
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    maxWidth: 1110,
    paddingLeft: 0,
    paddingRight: 0,
  },
  orderNow: {},
  textMenu: {
    color: "white",
    paddingHorizontal: 15,
    fontSize: 16,
    paddingVertical: 10,
  },
  right: {
    right: "-100%",
  },
  menuMain: {
    right: 0,
  },
})

import React from "react"
import { View } from "native-base"
import { ActivityIndicator, StyleSheet } from "react-native"
import screen from "@utils/screen"
import { Color } from "@theme/color"

interface IProps {
  isShow: boolean
}

const Loading = (props: IProps) => {
  if (!props.isShow) {
    return null
  }
  return (
    <View style={styles.container}>
      <ActivityIndicator animating={props.isShow} size="large" color={Color.Primary} />
    </View>
  )
}

export default Loading

const styles = StyleSheet.create({
  container: {
    width: screen.screenSize.width,
    height: screen.screenSize.height,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: "rgba(250, 250, 250, .4)",
  },
})

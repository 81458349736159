import { ActionType } from "@define/action"
import { call, put } from "redux-saga/effects"
import order from "@apis/order"
import { Toast } from "native-base"
import { navigate } from "@utils/navigation"
import store from "../index"
import Alert from "../../components/alert/Alert"

export function* createOrder(action: ActionType) {
  try {
    const response = yield call(order.createOrder, action.payload)

    if (response.data.isError === false) {
      const orderId = response.data.result.id

      try {
        const chargeResponse = yield call(order.chargeOrder, orderId, {
          stripe_token: action.payload.stripe_token,
        })
        store.store.dispatch({ type: "EMPTY_CART" })
        yield put({ type: "CHARGE_ORDER_SUCCESS", payload: chargeResponse.data.result })
        yield put({ type: "CREATE_ORDER_SUCCESS", payload: response.data.result })
        navigate("OrderDetail", { id: orderId })
      } catch (chargeErr) {
        Alert.alert("Failed to process payment", chargeErr.response.data.responseException.exceptionMessage)
        yield put({ type: "CREATE_ORDER_SUCCESS", payload: response.data.result })
        yield put({ type: "CHARGE_ORDER_FAILED", payload: chargeErr })
      }
    }
    // navigate("Payment", { id: response.data.result.id })
  } catch (error) {
    Alert.alert("Failed to process order", error.response.data.responseException.exceptionMessage)
    yield put({ type: "CREATE_ORDER_FAILED", payload: error })
  }
}

export function* getOrder(action: ActionType) {
  const { id } = action.payload
  try {
    const response = yield call(order.getOrder, id)
    yield put({ type: "GET_ORDER_SUCCESS", payload: response.data.result })
  } catch (error) {
    yield put({ type: "GET_ORDER_FAILED", payload: error })
  }
}

export function* chargeOrder(action: ActionType) {
  const { id } = action.payload
  try {
    const response = yield call(order.chargeOrder, id, action.payload)
    yield put({ type: "CHARGE_ORDER_SUCCESS", payload: response.data.result })
    store.store.dispatch({ type: "EMPTY_CART" })
    navigate("OrderDetail", { id })
  } catch (error) {
    Alert.alert("Failed to process payment", error.response.data.responseException.exceptionMessage)
    yield put({ type: "CHARGE_ORDER_FAILED", payload: error })
  }
}

export function* pickupOrder(action: ActionType) {
  const { id, status, note } = action.payload
  try {
    const response = yield call(order.pickupOrder, id, { status, note })
    yield put({ type: "PICKUP_ORDER_SUCCESS", payload: response.data.result })
    store.store.dispatch({ type: "GET_ORDER", payload: { id } })
    Alert.alert("I’ve arrived", "Thank you for your order. We will bring it to you")
  } catch (error) {
    yield put({ type: "PICKUP_ORDER_FAILED", payload: error })
  }
}

import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import rootReducer from "../store/reducers/root.reducer"
import rootSaga from "../store/sagas/root.saga"
import { loadState, saveState } from "@utils/state"

export const logger = (store: any) => (next: any) => (action: any) => {
  next(action)
}

const persistedState = loadState()
const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, persistedState, applyMiddleware(sagaMiddleware, logger))
sagaMiddleware.run(rootSaga)

store.subscribe(() => {
  saveState({
    cart: store.getState().cart,
  })
})

export default { store }

import React from "react"
import { Container, Left, Right, Content, Icon, List, ListItem, View, Text } from "native-base"
import { Color } from "@theme/color"
import { StyleSheet, Image } from 'react-native';
import TitleBold from "@components/TitleBold"
import FoodItem from "@components/FoodItem"
import { connect } from "react-redux"
import { getServingProducts } from "@store/actions/product.action"
import { navigate } from "@utils/navigation"
import Loading from "@components/Loading"
import { Layout } from "../theme/layout"
import FooterPage from "@components/options/FooterPage"
import HeaderPage from "@components/options/HeaderPage"
import { widthWindow } from "@utils/number"
import { flatten } from "@utils/style"
import { getMenus } from "@store/actions/menu.action"
import { MenuModel } from "@define/models/menu"
import { isActive } from "@utils/time"
import { ImageBackground } from "react-native";

interface IProps {
  route: any
  isLoading: boolean
  products: any[]
  getServingProducts: (payload: any) => void
  getMenus: () => void
  menus: MenuModel[]
  utc_offset: number
  timezone: string
}

class OrderNowScreen extends React.Component<IProps, any> {
  componentDidMount() {
    this.props.getServingProducts({})
    this.props.getMenus()
  }
  totalProduct = (): number => {
    return this.props.products.length
  }
  totalMenu = (): number => {
    return this.props.menus?.filter((e) => e.is_enabled === true && isActive(e, this.props.utc_offset, this.props.timezone))?.length
  }
  render() {
    return (
      <Container style={styles.Container}>
        <HeaderPage />
        <Loading isShow={this.props.isLoading} />
        <Content>
          {!this.props.isLoading && this.totalProduct() > 0 && this.totalMenu() > 0 && (
            <View style={flatten([Layout.container, { paddingHorizontal: widthWindow < 992 && 15 }])}>
              <View style={{ marginTop: widthWindow < 992 ? 100 : 140 }}>
                <Text style={Layout.sectionTitle}>Menu</Text>
                  <View style={{ marginTop: 30, marginBottom: 50, backgroundColor: "white", padding: widthWindow < 992 ? 15 : 30, borderRadius: 16 }}>
                    <List>
                      <ListItem itemHeader first>
                        <TitleBold title={this.totalProduct() + " items"} />
                      </ListItem>
                      {this.props.products.map((product, index) => (
                        <ListItem key={index} onPress={() => navigate("Product", { id: product.id })} style={{ backgroundColor: "white", marginLeft: 0, paddingTop: 25, paddingBottom: 25 }}>
                          <Left>
                            <FoodItem title={product.name} description={product.short_description} price={product.price} url={product.image_url} />
                          </Left>
                          <Right>
                            <Icon name={"chevron-right"} type={"MaterialCommunityIcons"} style={{ color: "#888" }} />
                          </Right>
                        </ListItem>
                      ))}
                    </List>
                  </View>
              </View>
            </View>
          )}
          {!this.props.isLoading && (this.totalProduct() <= 0 || this.totalMenu() <= 0) && (
            <ImageBackground source={require("@assets/images/closed.jpg")}>
              <View style={flatten([Layout.container, { paddingHorizontal: widthWindow < 992 && 15 }])}>
                <View style={{ marginTop: widthWindow < 992 ? 100 : 140 }}>
                    <View style={{ height: 600 }} />
                </View>
              </View>
            </ImageBackground>
          )}
          {/* <View style={flatten([Layout.container, { paddingHorizontal: widthWindow < 992 && 15 }])}>
            {!this.props.isLoading && (
              <View style={{ marginTop: widthWindow < 992 ? 100 : 140 }}>
                {this.totalProduct() > 0 && this.totalMenu() > 0 && <Text style={Layout.sectionTitle}>Menu</Text>}
                {this.totalProduct() > 0 && this.totalMenu() > 0 ? (
                  <View style={{ marginTop: 30, marginBottom: 50, backgroundColor: "white", padding: widthWindow < 992 ? 15 : 30, borderRadius: 16 }}>
                    <List>
                      <ListItem itemHeader first>
                        <TitleBold title={this.totalProduct() + " items"} />
                      </ListItem>
                      {this.props.products.map((product, index) => (
                        <ListItem key={index} onPress={() => navigate("Product", { id: product.id })} style={{ backgroundColor: "white", marginLeft: 0, paddingTop: 25, paddingBottom: 25 }}>
                          <Left>
                            <FoodItem title={product.name} description={product.short_description} price={product.price} url={product.image_url} />
                          </Left>
                          <Right>
                            <Icon name={"chevron-right"} type={"MaterialCommunityIcons"} style={{ color: "#888" }} />
                          </Right>
                        </ListItem>
                      ))}
                    </List>
                  </View>
                ) : (
                  <View style={{ marginBottom: 20 }}>
                  <ImageBackground source={require("@assets/images/closed.jpg")}>
                    <View style={{ height: 500 }} />
                  </ImageBackground>
                  </View>
                )}
              </View>
            )}
          </View> */}
          <FooterPage />
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.product.isLoading,
    products: state.product.servingProducts,
    menus: state.menu.items.reverse(),
    utc_offset: state.setting.restaurant_settings.utc_offset,
    timezone: state.setting.restaurant_settings.time_zone,
  }
}

export default connect(mapStateToProps, { getServingProducts, getMenus })(OrderNowScreen)
const styles = StyleSheet.create({
  Container: {
    backgroundColor: "#ede9eb",
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Color.Primary,
    paddingLeft: 15,
    paddingRight: 15,
  },
  listActionItem: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
  },
})

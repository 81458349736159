import React from "react"
import { Container, Content, Row } from "native-base"
import { Color } from "@theme/color"
import { Image, ImageBackground, StyleSheet, TouchableHighlight, View, Text } from "react-native"
import { Layout } from "@theme/layout"
import DiskIcon from "@components/icons/disk.icon"
import { ScrollView } from "react-native-gesture-handler"
import { connect } from "react-redux"
import { getMenus } from "@store/actions/menu.action"
import { MenuModel } from "../define/models/menu"
import { default as OverlaySpinner } from "react-native-loading-spinner-overlay"
import { navigate } from "@utils/navigation"
import ImageDefault from "@components/options/imageDefault"
import FooterPage from "@components/options/FooterPage"
import HeaderPage from "@components/options/HeaderPage"

interface IProps {
  isLoading: boolean
  items: MenuModel[]
  getMenus: (payload: any) => void
}

class HomeScreen extends React.Component<IProps, any> {
  showMenus: boolean = false

  constructor(props: IProps) {
    super(props)
  }

  componentDidMount() {
    this.props.getMenus({})
  }

  render() {
    return (
      <Container>
        <OverlaySpinner visible={this.props.isLoading} textContent="" />
        <View nativeID={"home-page"}>
          <HeaderPage />
          <Content>
            <View nativeID="home-outer">
              <ImageBackground source={require("@assets/images/home-background.jpg")}>
                <View style={styles.homeBg}>
                  <View style={styles.topBanner}>
                    {/* <Image source={require("@assets/images/image-banner.png")} style={{ width: 200, height: 200 }} /> */}
                    <Text style={[styles.titleBanner, styles.titleBannerTop]}>WINNER</Text>
                    <Text style={styles.titleBanner}>THE BEST MONGOLIAN BBQ IN IDAHO</Text>
                    <Text style={styles.titleBanner}>2022</Text>
                  </View>
                  {this.showMenus && (
                    <ScrollView horizontal={true}>
                      <Row style={styles.botBanner}>
                        {this.props.items.map((item, index) => (
                          <View style={{ paddingHorizontal: 7 }} key={index}>
                            <TouchableHighlight
                              underlayColor={Color.Yellow}
                              onPress={() => {
                                navigate("Menu", { id: item.id })
                              }}
                              style={styles.colBanner}
                              activeOpacity={1}
                            >
                              <View style={StyleSheet.flatten([Layout.dFlex, { justifyContent: "center" }])}>
                                <View
                                  style={{
                                    borderRightWidth: 1,
                                    borderColor: "white",
                                    alignItems: "center",
                                    paddingRight: 10,
                                  }}
                                >
                                  <ImageDefault image_url={item.image_url} />
                                </View>
                                <View style={{ paddingLeft: 10 }}>
                                  <Text style={StyleSheet.flatten([styles.textCol, { fontSize: 18 }])}>{item.name}</Text>
                                  <Text style={StyleSheet.flatten([styles.textCol])}>BEFORE {item.end_time}</Text>
                                  <Text style={StyleSheet.flatten([styles.textCol, { paddingBottom: 0 }])}>ORDER NOW</Text>
                                </View>
                              </View>
                            </TouchableHighlight>
                          </View>
                        ))}
                      </Row>
                    </ScrollView>
                  )}
                  <View style={{ alignItems: "center", justifyContent: "center" }} nativeID="now-order">
                    <TouchableHighlight
                      underlayColor={Color.Yellow}
                      onPress={() => {
                        navigate("OrderNow")
                      }}
                      style={StyleSheet.flatten([styles.colBanner])}
                      activeOpacity={1}
                    >
                      <View style={StyleSheet.flatten([Layout.dFlex, { justifyContent: "center" }])}>
                        <View
                          style={{
                            borderRightWidth: 1,
                            borderColor: "white",
                            alignItems: "center",
                            paddingRight: 22,
                          }}
                        >
                          <DiskIcon height={56} width={56} style={{ width: 56, height: 56 }} />
                        </View>
                        <View style={{ paddingLeft: 22, alignItems: "center" }}>
                          <Text style={StyleSheet.flatten([styles.textCol, { paddingBottom: 0, fontSize: 24 }])}>ORDER</Text>
                          <Text style={StyleSheet.flatten([styles.textCol, { paddingBottom: 0, fontSize: 34 }])}>NOW</Text>
                        </View>
                      </View>
                    </TouchableHighlight>
                  </View>
                </View>
              </ImageBackground>
            </View>
            <FooterPage />
          </Content>
        </View>
      </Container>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.menu.isLoading,
    items: state.menu.items,
  }
}

export default connect(mapStateToProps, { getMenus })(HomeScreen)
const styles = StyleSheet.create({
  homeBg: {
    paddingTop: 150,
  },
  topBanner: {
    alignItems: "center",
    paddingBottom: 30,
  },
  botBanner: {
    paddingTop: 25,
    paddingBottom: 10,
  },
  titleBanner: {
    fontSize: 56,
    color: "white",
    textAlign: "center",
    lineHeight: 60,
    maxWidth: 800,
    paddingTop: 20,
  },
  titleBannerTop: {
    paddingTop: 100
  },
  colBanner: {
    borderColor: "#360a24",
    borderRadius: 10,
    borderWidth: 2,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#642A4E",
  },
  textCol: {
    color: "white",
    textTransform: "uppercase",
    paddingBottom: 7,
    fontSize: 13,
  },
})

import Api from "@config/axios"

function getProduct(productId: string) {
  return Api.get(`product-skus/${productId}`)
}

function getServingProducts() {
  return Api.get('product-skus/now')
}

export default {
  getProduct,
  getServingProducts
}
import React, { useState, useEffect } from "react"
import { View } from "native-base"
import { StyleSheet, Text } from "react-native"
import moment from "moment"
import momentTz from "moment-timezone"
import { Color } from "@theme/color"

interface IProps {
  utc_offset: number
  timezone: string
}
const EleClock = (props: IProps) => {
  const [now, setNow] = useState(momentTz().tz(props.timezone))
  const [display, setDisplay] = useState(now.format("HH : mm : ss"))

  useEffect(() => {
    setInterval(() => {
      setNow(now.add(1, "seconds"))
      setDisplay(now.format("HH : mm : ss"))
    }, 1000)
  }, [])

  return (
    <View style={styles.container}>
      <View style={{ width: 165 }}>
        <Text style={{ color: Color.Main, fontSize: 20 }}>Time:</Text>
        <Text style={styles.text}>{display}</Text>
      </View>
    </View>
  )
}

export default EleClock

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end",
  },
  text: {
    color: "#101010",
    fontSize: 40,
    fontWeight: "bold",
  },
})

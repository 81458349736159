import { ActionType } from "@define/action"

const initState = {
  isLoading: false,
  item: null,
  servingProducts: [],
  error: "",
}

const productReducer = (state = initState, { type, payload }: ActionType) => {
  switch (type) {
    case "GET_PRODUCT":
      return {
        ...state,
        isLoading: true,
        error: "",
      }
    case "GET_PRODUCT_SUCCESS":
      return {
        ...state,
        item: payload,
        isLoading: false,
        error: "",
      }
    case "GET_PRODUCT_FAILED":
      return {
        ...state,
        isLoading: false,
        error: "",
      }
    case "GET_SERVING_PRODUCTS":
      return {
        ...state,
        isLoading: true,
        error: "",
      }
    case "GET_SERVING_PRODUCTS_SUCCESS":
      return {
        ...state,
        servingProducts: payload.items,
        isLoading: false,
        error: "",
      }
    case "GET_SERVING_PRODUCTS_FAILED":
      return {
        ...state,
        isLoading: false,
        error: "",
      }
    default:
      return state
  }
}

export default productReducer

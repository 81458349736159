import { StyleSheet } from "react-native"
import { widthWindow } from "@utils/number"
import { Color } from "./color"

export const Layout = StyleSheet.create({
  container: {
    maxWidth: 1110,
    alignSelf: "center",
    width: "100%",
    flexGrow: 1,
    flex: 1,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  dFlex: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerLogo: {
    width: 194,
    height: 37,
  },
  Header: {
    backgroundColor: "#fff",
    paddingVertical: 10,
    borderBottomWidth: 0,
    height: 65,
    paddingBottom: 0,
  },
  title: {
    textTransform: "uppercase",
    color: "#333",
    fontWeight: "700",
    fontFamily: "Roboto",
  },
  sectionTitle: {
    fontSize: widthWindow < 992 ? 24 : 48,
    color: Color.Main,
    marginBottom: 30,
    lineHeight: widthWindow < 992 ? 25 : 50,
  },
  cartMini: {
    justifyContent: "center",
    width: 66,
    height: 35,
    borderRadius: 5,
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 6,
  },
  bgFooter: {
    paddingHorizontal: 15,
    paddingTop: 5,
    paddingBottom: 10,
  },
  footerCustom: {
    paddingBottom: 40,
    backgroundColor: "#fff",
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.3,
  },
  bold: {
    fontWeight: "bold",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Fester",
  },
})

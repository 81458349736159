import { Dimensions } from "react-native"

// const width = Math.round(Dimensions.get("window").width)
const width = Math.round(Dimensions.get("window").width)
const height = Math.round(Dimensions.get("window").height)

function getPercentFromWith(percent: number): number {
  return Math.round(width / percent)
}

function calculateHeightFromWidth(width: number, radioW: number, radioH: number): number {
  const withSize = Math.round(width / radioW)
  return Math.round(withSize * radioH)
}

export default {
  screenSize: { width, height },
  getPercentFromWith,
  calculateHeightFromWidth,
}

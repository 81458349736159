import { APP_URL } from "@env"

export const linking = {
  prefixes: APP_URL,
  config: {
    Menu: "menus/:id",
    Product: "products/:id",
    Cart: "cart",
    Checkout: "checkout",
    Payment: "payment/:id",
    OrderDetail: "orders/:id",
    OrderNow: "order-now",
    ThankYou: "thank-you",
  },
}

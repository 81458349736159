import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import menuReducer from './menu.reducer';
import productReducer from "./product.reducer"
import cartReducer from './cart.reducer';
import orderReducer from './order.reducer';
import settingReducer from './setting.reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    menu: menuReducer,
    product: productReducer,
    cart: cartReducer,
    order: orderReducer,
    setting: settingReducer
})

export default rootReducer
import { ActionType } from "@define/action"

const initState = {
  isLoading: false,
  currentOrder: null,
  err: "",
}

const orderReducer = (state = initState, { type, payload }: ActionType) => {
  switch (type) {
    case "CREATE_ORDER":
      return {
        ...state,
        isLoading: true,
      }
    case "CREATE_ORDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "CREATE_ORDER_FAILED":
      return {
        ...state,
        isLoading: false,
        err: "Error " + type,
      }
    case "GET_ORDER":
      return {
        ...state,
        isLoading: true,
      }
    case "GET_ORDER_SUCCESS":
      return {
        ...state,
        currentOrder: payload,
        isLoading: false,
      }
    case "GET_ORDER_FAILED":
      return {
        ...state,
        isLoading: false,
        err: "Error " + type,
      }
    case "CHARGE_ORDER":
      return {
        ...state,
        isLoading: true,
      }
    case "CHARGE_ORDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "CHARGE_ORDER_FAILED":
      return {
        ...state,
        isLoading: false,
        err: "Error " + type,
      }

    case "PICKUP_ORDER":
      return {
        ...state,
        isLoading: true,
      }
    case "PICKUP_ORDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
      }
    case "PICKUP_ORDER_FAILED":
      return {
        ...state,
        isLoading: false,
        err: "Error " + type,
      }

    default:
      return state
  }
}

export default orderReducer

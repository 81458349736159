import Api from "@config/axios"

function createOrder(data: any) {
    return Api.post('orders', data)
}

function getOrder(id: string) {
    return Api.get('orders/' + id)
}

function chargeOrder(id: string, params: any) {
    return Api.post('orders/' + id + '/charges', params)
}

function pickupOrder(id: string, params: any) {
    return Api.patch('orders/' + id + '/pickup', params)
}

export default {
    createOrder,
    getOrder,
    pickupOrder,
    chargeOrder
}
